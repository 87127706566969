import React, {
    JSXElementConstructor,
    ReactElement,
    useCallback,
    useEffect,
    useRef,
    useState,
} from 'react'
import {
    View,
    StyleProp,
    ViewStyle,
    StyleSheet,
    Image,
    Pressable,
    FlatList,
    FlatListProps,
    findNodeHandle,
} from 'react-native'
import DotPagination, {
    DotPaginationProps,
} from '../Pagination/DotPagination/DotPagination'
import leftArrowIcon from '../../assets/images/left-arrow.png'
import rightArrowIcon from '../../assets/images/right-arrow.png'
import { useTheme } from '../ThemeProvider/ThemeProvider'

export interface CarouselProps
    extends Omit<FlatListProps<any>, 'renderItem' | 'data'> {
    items: any[]
    renderItem: (
        item: any,
        index: number,
        dotPagination?: React.ReactNode,
    ) => ReactElement<JSXElementConstructor<any>>
    autoPlay?: boolean
    autoPlayIntervalMS?: number
    loop?: boolean
    onIndexChange?: (index: number) => void
    testID?: string
    containerStyle?: StyleProp<ViewStyle>
    contentContainerStyle?: StyleProp<ViewStyle>
    itemContainerStyle?: StyleProp<ViewStyle>
    paginationType?: `${PaginationTypes}`
    paginationViewStyle?: [StyleProp<ViewStyle>, StyleProp<ViewStyle>]
    paginationArrowIcons?: ArrowIcons
    paginationDotIcon?: DotPaginationProps
    maxItemsPerView?: number
}

const enum PaginationTypes {
    Dots = 'dots',
    Arrows = 'arrows',
    Both = 'both',
}

export interface ArrowIcons {
    arrows: [ArrowIcon, ArrowIcon]
    height: number
    width: number
}

export interface ArrowIcon {
    icon: React.ReactNode
    containerStyle?: StyleProp<ViewStyle>
    onPress?: () => void
}

const Carousel = (props: CarouselProps) => {
    const { theme } = useTheme();
    const styles = createStyles(theme);
    const {
        items,
        renderItem,
        autoPlay,
        autoPlayIntervalMS = 3000,
        containerStyle,
        contentContainerStyle,
        paginationType,
        paginationArrowIcons,
        loop,
        testID,
        paginationDotIcon,
        onIndexChange,
        maxItemsPerView,
        itemContainerStyle,
        ...rest
    } = props

    const interval = useRef(undefined)
    const flatListRef = useRef<FlatList>()
    const flatListItemRef = useRef<View>()

    const [layout, setLayout] = useState(undefined)
    const [cardLayoutWidth, setCardLayoutWidth] = useState(0)
    const [currentScrollIndex, setCurrentScrollIndex] = useState(0)
    const [refreshInterval, setRefreshInterval] = useState(undefined)
    const [initialItemCount, setInitialItemCount] = useState(1)

    const [arrowHeight, setArrowHeight] = useState(0)

    useEffect(() => {
        const nodeHandle = findNodeHandle(flatListItemRef.current)

        if (!nodeHandle) return

        // onLayoutProp triggers infinite loop on this nested element
        flatListItemRef.current.measure(
            // ts-expect-error - vars kept for documentation
            (x, y, width, height, pageX, pageY) => {
                setCardLayoutWidth(width)
            },
        )
    }, [layout?.width, flatListItemRef.current])

    useEffect(() => {
        if (!layout?.width || !cardLayoutWidth) return

        // Prevent rounding to 0. Minimum of 1 item per view is required.
        // Get min in case parent width contains all the items plus empty space
        const initialItemCount = Math.min(
            Math.max(Math.floor(layout.width / cardLayoutWidth), 1),
            items.length,
        )

        setInitialItemCount(initialItemCount)
    }, [cardLayoutWidth, layout?.width])

    useEffect(() => {
        if (!autoPlay) {
            return
        }

        interval.current = setInterval(() => {
            goForward(false)
        }, autoPlayIntervalMS)

        return () => {
            clearInterval(interval.current)
        }
    }, [autoPlay, refreshInterval, currentScrollIndex])

    const goBack = (restartAutoPlay: boolean = true) => {
        let newIndex = currentScrollIndex
        if (currentScrollIndex != 0) {
            newIndex--
        } else if (loop) {
            newIndex = items.length - initialItemCount
        }
        if (restartAutoPlay) {
            resetInterval()
        }
        setScrollIndex(newIndex)
    }

    const goForward = (restartAutoPlay: boolean = true) => {
        let newIndex = currentScrollIndex

        const isLastScroll =
            currentScrollIndex === items.length - initialItemCount

        if (!isLastScroll) {
            newIndex = currentScrollIndex + 1
        } else if (loop) {
            newIndex = 0
        }
        if (restartAutoPlay) {
            resetInterval()
        }
        setScrollIndex(newIndex)
    }

    const setScrollIndex = (index: number) => {
        onIndexChange?.(index)
        setCurrentScrollIndex(index)
        flatListRef?.current?.scrollToIndex({ animated: true, index })
    }

    const resetInterval = () => {
        clearInterval(interval.current)
        setRefreshInterval(new Date())
    }

    const getArrowPagination = () => {
        const leftArrowStyle = [
            styles.arrowIconContainer,
            { left: 0 },
            paginationArrowIcons?.arrows[0]?.containerStyle,
            { transform: [{ translateY: -arrowHeight / 2 }] },
        ]
        const rightArrowStyle = [
            styles.arrowIconContainer,
            { right: 0 },
            paginationArrowIcons?.arrows[1]?.containerStyle,
            { transform: [{ translateY: -arrowHeight / 2 }] },
        ]

        const onPressLeftIcon = () => {
            paginationArrowIcons?.arrows[0]?.onPress?.()
            goBack()
            resetInterval()
        }

        const onPressRightIcon = () => {
            goForward()
            resetInterval()
            paginationArrowIcons?.arrows[1]?.onPress?.()
        }

        return (
            <>
                <View
                    style={leftArrowStyle}
                    onLayout={(event) =>
                        setArrowHeight(event.nativeEvent.layout.height)
                    }
                >
                    <Pressable
                        testID={`${testID}-left-arrow`}
                        onPress={onPressLeftIcon}
                    >
                        {paginationArrowIcons ? (
                            paginationArrowIcons.arrows[0].icon
                        ) : (
                            <Image
                                source={leftArrowIcon}
                                style={styles.arrowIconImage}
                            />
                        )}
                    </Pressable>
                </View>
                <View style={rightArrowStyle}>
                    <Pressable
                        testID={`${testID}-right-arrow`}
                        onPress={onPressRightIcon}
                    >
                        {paginationArrowIcons ? (
                            paginationArrowIcons.arrows[1].icon
                        ) : (
                            <Image
                                source={rightArrowIcon}
                                style={styles.arrowIconImage}
                            />
                        )}
                    </Pressable>
                </View>
            </>
        )
    }

    const getDotPagination = useCallback(
        (id: number = 0) => {
            return (
                <DotPagination
                    testID={testID + '-' + id}
                    count={
                        initialItemCount
                            ? // 1 is added to count to take into account the initial item count
                              items.length - initialItemCount + 1
                            : 1
                    }
                    selectedIndex={currentScrollIndex}
                    onSelected={setScrollIndex}
                    containerStyle={{
                        marginBottom: 8,
                        marginTop: 8,
                    }}
                    dotStyle={{ backgroundColor: '#A666FF' }}
                    {...paginationDotIcon}
                />
            )
        },
        [currentScrollIndex, setScrollIndex, initialItemCount],
    )

    const isSingleItem = maxItemsPerView === 1

    const flatListWidth =
        maxItemsPerView && !isSingleItem && cardLayoutWidth
            ? maxItemsPerView * cardLayoutWidth
            : '100%'

    const renderArrowPagination =
        (paginationType === PaginationTypes.Arrows ||
            paginationType === PaginationTypes.Both) &&
        getArrowPagination()

    const renderDotPagination =
        (paginationType === PaginationTypes.Dots ||
            paginationType === PaginationTypes.Both) &&
        getDotPagination()

    const isCard = maxItemsPerView === 1

    return (
        <View
            style={[
                {
                    width: flatListWidth,
                },
                styles.container,
                containerStyle,
            ]}
            testID={testID}
        >
            <View style={styles.arrowViewContainer}>
                <FlatList
                    // Disable warning for onScrollToIndexFailed on unit test
                    // https://stackoverflow.com/a/60320726/15741905
                    onScrollToIndexFailed={(info) => {
                        const wait = new Promise((resolve) =>
                            setTimeout(resolve, 500),
                        )
                        wait.then(() => {
                            flatListRef.current?.scrollToIndex({
                                index: info.index,
                                animated: true,
                            })
                        })
                    }}
                    testID={`${testID}-flat-list`}
                    contentContainerStyle={[
                        styles.contentContainer,
                        contentContainerStyle,
                    ]}
                    ref={flatListRef}
                    onLayout={(e) => {
                        setLayout(e.nativeEvent.layout)
                    }}
                    showsHorizontalScrollIndicator={false}
                    data={items}
                    keyExtractor={(_, index) => `carousel-${index}`}
                    horizontal
                    CellRendererComponent={({
                        children,
                        index,
                        style,
                        ...props
                    }) => {
                        if (!layout) {
                            return null
                        }
                        
                        if (isCard) {
                            return <>{children}</>
                        }
                        return (
                            <View
                                testID={`${testID}-carousel-item-${index}`}
                                key={`carousel-${index}`}
                                ref={index === 0 ? flatListItemRef : null}
                                style={[
                                    styles.itemContainer,
                                    itemContainerStyle,
                                ]}
                                {...props}
                            >
                                {children}
                            </View>
                        )
                    }}
                    renderItem={({ item, index }) =>
                        renderItem(item, index, getDotPagination(index))
                    }
                    {...rest}
                />
                {!isCard && renderArrowPagination}
            </View>
            {layout && renderDotPagination}
            {isCard && renderArrowPagination}
        </View>
    )
}

const createStyles = (theme) => StyleSheet.create({
    container: { 
        overflow: 'hidden', 
        flex: 1 
    },
    arrowIconContainer: {
        position: 'absolute',
        height: theme?.icons?.size || 30,
        width: theme?.icons?.size || 30,
        backgroundColor: theme?.colors?.background || 'white',
        borderRadius: theme?.spacing?.borderRadius || 15,
        top: '50%',
    },
    arrowIconImage: {
        height: theme?.icons?.size || 30,
        width: theme?.icons?.size || 30,
    },
    itemContainer: {
        height: '100%',
    },
    arrowViewContainer: {
        flexGrow: 1,
    },
    contentContainer: {
        alignItems: 'stretch',
    },
})

export default Carousel


