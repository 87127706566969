import React, { useState, useEffect, useMemo } from 'react'
import { View, TextInput, StyleProp, ViewStyle, TextStyle } from 'react-native'
import { Typography } from '../Typography/Typography'
import { useTheme } from '../ThemeProvider/ThemeProvider'
import createStyles from './styles'

export interface TextAreaProps {
    placeholder?: string
    error?: string
    numberOfLines?: number
    maxLength?: number
    style?: StyleProp<ViewStyle>
    containerStyle?: StyleProp<ViewStyle>
    errorStyle?: StyleProp<TextStyle>
    onChangeText?: (text: string) => void
    textStyle?: StyleProp<TextStyle>
    highlightColor?: string
    label?: string
    showLimit?: boolean
    labelTextStyle?: StyleProp<TextStyle>
    characterLimitStyle?: StyleProp<TextStyle>
    labelContainerStyle?: StyleProp<ViewStyle>
    testID?: string
    defaultValue?: string
    isActive?: boolean
    activeStyle?: StyleProp<ViewStyle>
    inactiveStyle?: StyleProp<ViewStyle>
}

export const getBorderColor = (
    theme: any,
    error?: string,
    color?: string,
    isActive?: boolean,
): string => {
    if (error) {
        return theme?.colors?.error || '#b00020'
    } else if (isActive) {
        return color || theme?.colors?.primary || '#1976d2'
    } else {
        return theme?.colors?.border || '#e0e0e0'
    }
}

const TextArea: React.FC<TextAreaProps> = (props) => {
    const { theme } = useTheme()
    const styles = createStyles(theme)

    const [value, setValue] = useState<string>(props.defaultValue || '')

    useEffect(() => {
        if (props.defaultValue) {
            setValue(props.defaultValue)
        }
    }, [props.defaultValue])

    const onChangeValue = (value: string) => {
        setValue(value)
        if (props.onChangeText) {
            props.onChangeText(value)
        }
    }

    const {
        placeholder,
        error,
        numberOfLines,
        maxLength = 50,
        style,
        errorStyle,
        textStyle,
        highlightColor,
        label,
        showLimit,
        labelTextStyle,
        characterLimitStyle,
        labelContainerStyle,
        testID,
        containerStyle,
        isActive = false,
        activeStyle,
        inactiveStyle,
    } = props

    const computedStyle = useMemo(() => {
        const borderColor = getBorderColor(theme, error, highlightColor, isActive)
        return [
            styles.container,
            { borderColor, borderWidth: isActive ? 2 : 1 },
            isActive ? activeStyle : inactiveStyle,
            style,
        ]
    }, [styles, theme, error, highlightColor, isActive, activeStyle, inactiveStyle, style])

    return (
        <View style={[containerStyle]} testID={testID}>
            {label && (
                <View
                    style={[styles.labelContainer, labelContainerStyle]}
                    testID={`${testID}-label-container`}
                >
                    <Typography
                        style={[styles.label, labelTextStyle]}
                        testID={`${testID}-label`}
                    >
                        {label}
                    </Typography>
                </View>
            )}
            <TextInput
                style={[styles.input, computedStyle, textStyle]}
                onChangeText={onChangeValue}
                value={value}
                placeholder={placeholder}
                placeholderTextColor={theme?.colors?.text || '#9E9E9E'}
                editable
                multiline
                numberOfLines={numberOfLines}
                maxLength={maxLength}
                testID={`${testID}-input`}
            />
            {showLimit && (
                <Typography
                    style={[styles.characterLimit, characterLimitStyle]}
                    testID={`${testID}-character-limit`}
                >
                    {`${value.length}/${maxLength}`}
                </Typography>
            )}
            {!!error && (
                <View testID={`${testID}-error-container`}>
                    <Typography
                        style={[styles.error, errorStyle]}
                        testID={`${testID}-error`}
                    >
                        {error}
                    </Typography>
                </View>
            )}
        </View>
    )
}

export default TextArea
