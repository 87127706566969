import React, { useCallback, useState } from 'react';
import { View, Text } from 'react-native';
import moment from 'moment';
import createStyles from './styles';
import { WeeklyView } from './WeeklyView';
import { MonthlyView } from './MonthlyView';
import { YearlyView } from './YearlyView';
import { DateInput } from './DateInput';
import { IMultiViewCalendar } from './interface';
import { useTheme } from '../../ThemeProvider/ThemeProvider';

const renderArrowFactory = (
  arrowLeft: React.ReactNode,
  arrowRight: React.ReactNode,
) => {
    const { theme } = useTheme();
    const styles = createStyles(theme);

    return (direction) => (
        <>
            {direction === 'right'
            ? arrowRight || (
                <View style={styles.navButton}>
                <Text style={styles.navButtonText}>→</Text>
                </View>
            ) : arrowLeft || (
                <View style={styles.navButton}>
                <Text style={styles.navButtonText}>←</Text>
                </View>
            )}
        </>
    );
};

const MultiViewCalendar = (props: IMultiViewCalendar) => {
  const {
    testID,
    currentDate = new Date(),
    arrowLeft,
    arrowRight,
    markedDates = {},
    viewType = 'monthly',
    theme: calendarTheme,
    hideInput = false,
    mainContainerStyles,
    dateInputContainerStyles,
    renderInput,
    ...restProps
  } = props;

  const { theme } = useTheme();
  const styles = createStyles(theme);
  const [selectedDate, setSelectedDate] = useState(moment(currentDate));
  const [_markedDates, setMarkedDates] = useState(markedDates);
  const [view, setView] = useState(viewType);
  const [selectedYear, setSelectedYear] = useState(moment(currentDate).year());
  const [inputValue, setInputValue] = useState(
    moment(currentDate).format('MMM D, YYYY'),
  );

  const renderArrow = renderArrowFactory(arrowLeft, arrowRight);

  const handleOnDayPress = useCallback(
    (day) => {
      const { dateString } = day;
      const copyMarkedDates = JSON.parse(JSON.stringify(_markedDates));
      Object.values(copyMarkedDates).forEach(
        (val: any) => delete val.selected,
      );

      const updateMarkedDates = {
        ...copyMarkedDates,
        [dateString]: {
          ..._markedDates[dateString],
          selected: true,
        },
      };
      setMarkedDates({ ...updateMarkedDates });
      const newDate = moment(day.timestamp);
      setSelectedDate(newDate);
      setInputValue(newDate.format('MMM D, YYYY'));
    },
    [_markedDates, selectedDate, inputValue],
  );

  const dateInput = (
    <DateInput
      hideInput={hideInput}
      dateInputContainerStyles={dateInputContainerStyles}
      renderInput={renderInput}
      inputValue={inputValue}
      view={view}
      setView={setView}
    />
  );

  const RenderView = () => {
    switch (view) {
      case 'weekly':
        return (
          <WeeklyView
            dateInput={dateInput}
            inputValue={inputValue}
            setInputValue={setInputValue}
            renderArrow={renderArrow}
            selectedDate={selectedDate}
            setSelectedDate={setSelectedDate}
            handleOnDayPress={handleOnDayPress}
            markedDates={_markedDates}
            theme={calendarTheme}
            {...restProps}
          />
        );
      case 'monthly':
        return (
          <MonthlyView
            dateInput={dateInput}
            renderArrow={renderArrow}
            view={view}
            setView={setView}
            selectedDate={selectedDate}
            setSelectedDate={setSelectedDate}
            handleOnDayPress={handleOnDayPress}
            markedDates={_markedDates}
            theme={calendarTheme}
            {...restProps}
          />
        );
      case 'yearly':
        return (
          <YearlyView
            dateInput={dateInput}
            setInputValue={setInputValue}
            selectedYear={selectedYear}
            setSelectedYear={setSelectedYear}
            renderArrow={renderArrow}
            view={view}
            setView={setView}
            selectedDate={selectedDate}
            setSelectedDate={setSelectedDate}
            {...restProps}
            />
          );
      }
    };
  
    return (
      <View style={[styles.mainContainer, mainContainerStyles]}>
        <RenderView />
      </View>
    );
  };
  
  export default MultiViewCalendar;
  
