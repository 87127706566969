// src/components/DataTable/component/DynamicRow.tsx
import React from 'react'
import { View, Text, StyleProp, ViewStyle, TextStyle } from 'react-native'

import createStyles from '../style'
import { createStyles as useContextMenuStyles } from '../../ContextMenu/styles'
import { useTheme } from '../../ThemeProvider/ThemeProvider'

interface DynamicRowProps {
    rowData: any
    renderItem: (item: any, index: number) => React.ReactNode
    tableDataContainerStyle?: StyleProp<ViewStyle>
    tableDataTextStyle?: StyleProp<TextStyle>
    index: number
    length: number
    headerData: Array<string>
    testID?: string
    key?: string
    ContextMenu?: React.ReactNode
}

const DynamicRow: React.FC<DynamicRowProps> = ({
    rowData,
    renderItem,
    tableDataContainerStyle,
    tableDataTextStyle,
    index,
    length,
    headerData,
    testID,
    ContextMenu,
}) => {
    const { theme } = useTheme()
    const tableStyles = createStyles(theme)
    const contextMenuStyles = useContextMenuStyles(theme)

    return (
        <View
            style={[tableStyles.tableRow, { zIndex: length - index }]}
            testID={testID}
        >
            <View style={[tableStyles.header]}>
                {headerData?.map((headerValue: string, idx: number) => {
                    return renderItem ? (
                        renderItem(rowData[idx], idx)
                    ) : typeof rowData[idx] == 'string' ? (
                        <Text
                            style={[tableStyles.headerText, tableDataTextStyle]}
                            key={idx}
                        >
                            {rowData[idx]}
                        </Text>
                    ) : (
                        <View
                            style={[
                                tableStyles.headerText,
                                tableStyles.headerDataContainerStyle,
                            ]}
                            key={idx}
                        >
                            <>{rowData[idx]}</>
                        </View>
                    )
                })}
                {ContextMenu && (
                    <View
                        style={[
                            contextMenuStyles.container,
                            tableDataContainerStyle,
                        ]}
                    >
                        {ContextMenu}
                    </View>
                )}
            </View>
        </View>
    )
}

export default DynamicRow
