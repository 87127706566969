import React, { Component } from 'react'
import {
    Pressable,
    View,
    Text,
    StyleProp,
    ViewStyle,
    TextStyle,
    PressableProps,
} from 'react-native'
import { useTheme } from '../ThemeProvider/ThemeProvider'
import createStyles from './styles'
import COLORS from '../../utils/colors'

export interface SegmentedCtrlItemProps {
    height?: number
    textStyle?: StyleProp<TextStyle>
    item: SegmentedCtrlItemData
    index: number
    itemStyle?: ViewStyle
    selectedId?: string
    itemSelectdBgColor?: string
    itemSelectdTxtColor?: string
    testID?: string
}

export interface SegmentedCtrlItemData extends PressableProps {
    id: string
    name: string
    icon?: React.ReactNode
    selectedIcon?: React.ReactNode
}

const SegmentedCtrlItem: React.FC<SegmentedCtrlItemProps> = ({
    height,
    textStyle,
    item,
    index,
    itemStyle,
    selectedId,
    itemSelectdBgColor = '',
    itemSelectdTxtColor = '',
    testID,
    ...otherPressableProps
}) => {
    const { theme } = useTheme()
    const styles = createStyles(theme) // Provide default theme if undefined

    return (
        <Pressable
            style={[
                styles.button,
                itemStyle,
                { height },
                { borderWidth: selectedId === item?.id ? 1 : 0 },
                {
                    borderColor: itemStyle?.borderColor
                        ? itemStyle.borderColor
                        : theme?.colors?.border || COLORS.COLOR_BLUE,
                },
                {
                    backgroundColor:
                        selectedId === item?.id
                            ? itemSelectdBgColor !== ''
                                ? itemSelectdBgColor
                                : theme?.colors?.secondaryBackground ||
                                  COLORS.COLOR_SEGMNTD
                            : theme?.colors?.background || COLORS.COLOR_INFO,
                },
                { borderRadius: selectedId === item?.id ? 1000 : 0 },
            ]}
            {...otherPressableProps}
            testID={testID + '-' + index}
        >
            {item?.icon &&
                (selectedId === item?.id ? item.selectedIcon : item.icon)}
            <Text
                style={[
                    styles.text,
                    {
                        color:
                            selectedId === item?.id
                                ? itemSelectdTxtColor !== ''
                                    ? itemSelectdTxtColor
                                    : theme?.colors?.primary ||
                                      COLORS.COLOR_MEDIUMBLUE
                                : theme?.colors?.text || COLORS.COLOR_GRAY,
                    },
                    textStyle,
                ]}
            >
                {item?.name}
            </Text>
        </Pressable>
    )
}

export default SegmentedCtrlItem
