import React, { useState, useCallback } from 'react'
import { View, ScrollView, Text } from 'react-native'
import DynamicRow from './component/DynamicRow'
import createStyles from './style'
import { createStyles as createContextMenuStyles } from '../ContextMenu/styles'
import getPlatformType from '../../utils/getPlatformType'
import { DataTableProps } from './interface'
import { useTheme } from '../ThemeProvider/ThemeProvider'

const DataTable: React.FC<DataTableProps> = (props) => {
    const {
        headerData,
        renderItem,
        ContextMenu,
        tableData,
        tableTitle,
        headerDataContainerStyle,
        titleStyle,
        titleContainerStyle,
        containerStyle,
        testID,
    } = props

    const [updatedHeaderData, setUpdatedHeaderData] = useState([...headerData])

    const renderRow = useCallback(
        ({ item, index, length }) => {
            return (
                <DynamicRow
                    rowData={item}
                    length={length}
                    index={index}
                    headerData={updatedHeaderData}
                    ContextMenu={ContextMenu}
                    renderItem={renderItem}
                    testID={`dataTableRow-${index}`}
                    key={index}
                />
            )
        },
        [ContextMenu, renderItem, updatedHeaderData],
    )

    const renderRows = useCallback(
        (tableData: Array<Object>) => {
            return tableData.map((item, index) => {
                return renderRow({
                    item,
                    index,
                    length: tableData.length,
                })
            })
        },
        [renderRow],
    )

    const renderRowsMobile = useCallback(
        (tableData: Array<Object>) => {
            const { theme } = useTheme()
            const styles = createStyles(theme)
            return (
                <ScrollView
                    horizontal={true}
                    showsHorizontalScrollIndicator={false}
                    contentContainerStyle={styles.mobileTableContent}
                >
                    <ScrollView>{renderRows(tableData)}</ScrollView>
                </ScrollView>
            )
        },
        [renderRows],
    )

    const { theme } = useTheme()
    const styles = createStyles(theme)
    const contextMenuStyles = createContextMenuStyles(theme)

    const tableRows =
        getPlatformType() === 'phone'
            ? renderRowsMobile(tableData)
            : renderRows(tableData)

    return (
        <View style={styles.scrollContainer} testID={testID}>
            <View style={[styles.container, containerStyle]}>
                <View style={[styles.tableRow]}>
                    <View style={[styles.header]}>
                        {updatedHeaderData.map(
                            (headerValue: string, index: number) => (
                                <Text style={styles.headerText} key={index}>
                                    {headerValue}
                                </Text>
                            ),
                        )}
                        {ContextMenu && (
                            <View
                                style={[
                                    contextMenuStyles.container,
                                    styles.autoWidthCell,
                                    headerDataContainerStyle,
                                ]}
                            ></View>
                        )}
                    </View>
                </View>
                {tableTitle && (
                    <View style={[styles.textStyle, titleContainerStyle]}>
                        <Text style={[styles.titleStyle, titleStyle]}>
                            {tableTitle}
                        </Text>
                    </View>
                )}
                {tableRows}
            </View>
        </View>
    )
}

export default DataTable
