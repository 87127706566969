import React from 'react'
import { Svg, Circle } from 'react-native-svg'
import { useTheme } from '../ThemeProvider/ThemeProvider'


export const RadioButtonCheckedIcon = (props) => {
    const { theme } = useTheme()
    const color = theme?.colors?.primary || '#0000ff' // Default to blue if undefined
    const size = props.size || 20

    return (
        <Svg width={size} height={size} viewBox="0 0 20 20" fill="none" {...props}>
            <Circle
                cx="10"
                cy="10"
                r="9"
                stroke={color}
                strokeWidth="1"
                fill={color}
            />
            <Circle cx="10" cy="10" r="4" fill="white" />
        </Svg>
    )
}

