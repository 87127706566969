import React, { FC } from 'react'
import { View, StyleSheet, PressableProps, Pressable, Text } from 'react-native'
import { useTheme } from '../ThemeProvider/ThemeProvider'
import { Size } from '../../utils/constants'
import { CardBaseProps, CardTitleProps } from '../Card/interface'
import { BuilderTheme } from '../ThemeProvider/themes'
import COLORS from '../../../src/utils/colors'

export interface EventCardProps extends CardBaseProps, CardTitleProps {
    participant?: string
    pressableProps?: PressableProps
    size?: Size
}

export const EventCardLoading: React.FC<EventCardProps> = ({ size }) => {
    const isMaxSize = size === 'max'

    const { theme } = useTheme()
    const styles = createStyles(theme)

    const containerStyle = isMaxSize
        ? {
              ...styles.eventCardMaxStyle,
              paddingHorizontal: 20,
          }
        : { ...styles.eventCardMinStyle, paddingHorizontal: 10 }

    return (
        <View
            testID="event-card-loading-container"
            style={[styles.eventCardLoadingContainer, containerStyle]}
        >
            <View
                testID="event-title-loading"
                style={{
                    ...styles.eventTitleLoading,
                    width: isMaxSize ? '20%' : '100%',
                }}
            />

            {isMaxSize && (
                <View
                    testID="event-participant-loading"
                    style={styles.eventTitleLoading}
                />
            )}
        </View>
    )
}

export const EventCard: FC<EventCardProps> = ({
    size,
    testID,
    pressableProps,
    title,
    participant,
    loading,
    containerStyle,
}) => {
    const isMaxSize = size === 'max'

    const { theme } = useTheme()
    const styles = createStyles(theme)

    const sizeStyle = isMaxSize
        ? { ...styles.eventCardMaxStyle, paddingHorizontal: 20 }
        : { ...styles.eventCardMinStyle, paddingHorizontal: 10 }

    if (loading) {
        return <EventCardLoading size={size} />
    }

    return (
        <Pressable
            {...pressableProps}
            style={[
                styles.eventCardContainer,
                sizeStyle,
                containerStyle,
            ]}
            testID={`${testID}-event-card`}
        >
            <Text style={styles.eventNameStyle}>{title}</Text>

            {isMaxSize && (
                <Text style={styles.eventParticipantStyle}>
                    {participant}
                </Text>
            )}
        </Pressable>
    )
}

const createStyles = (theme: BuilderTheme) => StyleSheet.create({
    eventCardContainer: {
        backgroundColor: theme?.colors?.secondaryBackground ?? '#F0E5FF',
        borderRadius: theme?.spacing?.borderRadius ?? 6,
        borderLeftColor: theme?.colors?.border ?? COLORS.COLOR_PRIMARY_PURPLE,
        borderLeftWidth: theme?.spacing?.borderWidth ?? 8,
        alignItems: 'center',
        flexDirection: 'row',
        justifyContent: 'space-between',
    },
    eventCardMaxStyle: {
        width: 718,
        height: 41,
    },
    eventCardMinStyle: {
        width: 101,
        height: 41,
    },
    eventNameStyle: {
        fontWeight: theme?.typography?.fontWeight?.bold ?? 'bold',
        fontSize: theme?.typography?.size?.xs?.fontSize ?? 12,
    },
    eventParticipantStyle: {
        fontSize: theme?.typography?.size?.xs?.fontSize ?? 12,
    },
    // Loading
    eventCardLoadingContainer: {
        paddingHorizontal: theme?.spacing?.padding ?? 20,
        backgroundColor: theme?.colors?.secondaryBackground ?? '#F0E5FF',
        borderRadius: theme?.spacing?.borderRadius ?? 6,
        borderLeftColor: theme?.colors?.border ?? COLORS.COLOR_PRIMARY_PURPLE,
        borderLeftWidth: theme?.spacing?.borderWidth ?? 8,
        alignItems: 'center',
        flexDirection: 'row',
        justifyContent: 'space-between',
    },
    eventTitleLoading: {
        width: '20%',
        height: 10,
        backgroundColor: theme?.colors?.disabled ?? COLORS.COLOR_BTNGRAY, // Safely using theme color with default
        borderRadius: theme?.spacing?.borderRadius ?? 8,
    },
})
