import { StyleSheet } from 'react-native'
import { BuilderTheme } from '../ThemeProvider/themes'
import COLORS from '../../utils/colors'

const createStyles = (theme?: BuilderTheme) =>
    StyleSheet.create({
        button: {
            alignItems: 'center',
            justifyContent: 'center',
            flexDirection: 'row',
            paddingHorizontal: theme?.spacing?.padding ?? 10,
        },
        text: {
            marginHorizontal: theme?.spacing?.margin ?? 10,
            fontWeight: theme?.typography?.fontWeight?.bold ?? '700',
            fontSize: theme?.typography?.size?.base?.fontSize ?? 16,
            lineHeight: theme?.typography?.size?.base?.lineHeight ?? 24,
        },
        flatlist: {
            borderRadius: 1000,
            backgroundColor: theme?.colors?.background ?? COLORS.COLOR_INFO,
            padding: theme?.spacing?.padding ?? 4,
            alignItems: 'center',
            overflow: 'hidden',
            flexWrap: 'nowrap',
        },
        calendarIcon: {
            width: theme?.icons?.size ?? 16,
            height: theme?.icons?.size ?? 16,
            marginLeft: theme?.spacing?.margin ?? 10,
        },
    })

export default createStyles
