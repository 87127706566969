import React, { useState, useEffect } from 'react'
import { FlatList, ScrollView, Pressable, View, Text } from 'react-native'
import { useTheme } from '../../ThemeProvider/ThemeProvider'
import LinearGradientComponent from '../../LinearGradient'
import { MenuData, SideMenuProps } from './interface'
import createStyles from './style'

const SideMenu: React.FC<SideMenuProps> = (props) => {
    const { theme } = useTheme()
    const styles = createStyles(theme)

    const [iconData, setIconData] = useState(false)
    const [selectedId, setSelectedId] = useState<number | string>(props.selectedId || 0)

    useEffect(() => {
        setSelectedId(props.selectedId || 0)
    }, [props.selectedId])

    const handleOnPress = (e: any, item: MenuData) => {
        if (item?.onPress) {
            item?.onPress(e, item)
        }
        setSelectedId(item?.id)
    }

    const getSelectedMenu = (menuData = props.menuData) => {
        if (!menuData) return

        for (const item of menuData) {
            if (item.id === selectedId) {
                return item
            }

            if (item.children) {
                const selected = getSelectedMenu(item.children)
                if (selected) {
                    return selected
                }
            }
        }
    }

    const renderSubMenu = ({ item }: any) => {
        const {
            sideMenuButtonTouchStyle,
            sideMenuButtonStyle,
            sideMenuTouchNameStyle,
            sideMenuNameStyle,
            sideMenuDescriptionTouchStyle,
            sideMenuDescriptionStyle,
        } = props

        const isSelected = item.id === selectedId

        return (
            <Pressable
                key={item.id}
                style={
                    isSelected
                        ? [styles.sideMenuButtonTouchStyle, sideMenuButtonTouchStyle]
                        : [styles.sideMenuButtonStyle, sideMenuButtonStyle]
                }
                onPress={(e) => handleOnPress(e, item)}
            >
                {isSelected ? item?.pressImage || item?.image : item?.image}
                <View style={styles.subMenuTextContainer}>
                    {item?.name ? (
                        <Text
                            style={
                                isSelected
                                    ? [styles.sideMenuTouchNameStyle, sideMenuTouchNameStyle]
                                    : [styles.sideMenuNameStyle, sideMenuNameStyle]
                            }
                        >
                            {item?.name}
                        </Text>
                    ) : null}
                    {item?.description ? (
                        <Text
                            style={
                                isSelected
                                    ? [styles.sideMenuDescriptionTouchStyle, sideMenuDescriptionTouchStyle]
                                    : [styles.sideMenuDescriptionStyle, sideMenuDescriptionStyle]
                            }
                        >
                            {item?.description}
                        </Text>
                    ) : null}
                </View>
            </Pressable>
        )
    }

    // Finds the top level parent and one level upper parent of the selected menu
    const getSelectedTopParent = (menuData = props.menuData) => {
        if (!menuData) return []

        for (const menu of menuData) {
            if (menu.id === selectedId) {
                return [menu]
            }

            if (menu.children) {
                const [topParent, parent] = getSelectedTopParent(menu.children)

                // this is the first return
                if (topParent && !parent) {
                    return [menu, menu]
                }

                // this is the consecutive returns, carry the menu (grandparent) to the top
                if (topParent) {
                    return [menu, parent]
                }
            }
        }

        return []
    }

    const renderMainMenu = ({ item }: any) => {
        const {
            sideTabButtonTouchTextStyle,
            sideTabButtonTextStyle,
            barGradientColor,
            selectedTabGradientColor,
            selectedId: propsSelectedId,
        } = props

        const selectedMenu = getSelectedMenu()
        const [topParent] = getSelectedTopParent()

        const isSelected = item.id === selectedMenu?.id || item.id === topParent?.id

        return (
            <LinearGradientComponent
                colors={
                    item.id == propsSelectedId
                        ? selectedTabGradientColor?.length > 0
                            ? selectedTabGradientColor
                            : []
                        : ['transparent', 'transparent']
                }
                style={{ borderRadius: 0 }}
            >
                                <View style={styles.tabView}>
                    <Pressable
                        style={styles.sidebuttoniconNameStyle}
                        onPress={(e) => handleOnPress(e, item)}
                    >
                        {isSelected ? item?.pressImage || item?.image : item?.image}
                        <Text
                            style={
                                isSelected
                                    ? [styles.sideTabButtonTouchTextStyle, sideTabButtonTouchTextStyle]
                                    : [styles.sideTabButtonTextStyle, sideTabButtonTextStyle]
                            }
                        >
                            {item?.name}
                        </Text>
                    </Pressable>
                    {isSelected && (
                        <>
                            {barGradientColor?.length > 0 && (
                                <LinearGradientComponent colors={barGradientColor}>
                                    <View style={styles.backViewStyle} />
                                </LinearGradientComponent>
                            )}
                        </>
                    )}
                </View>
            </LinearGradientComponent>
        )
    }

    const {
        menuData,
        mainMenuVisible,
        mainMenuContainerStyle,
        subMenuContainerStyle,
        subMenuListStyle,
        subMenuTitleStyle,
        bottomContent,
        topContent,
        subMenuBottomContent,
        subMenuTopContent,
        style,
        testID,
    } = props

    const selectedMenu = getSelectedMenu()
    const hasSubMenu = selectedMenu?.children?.length > 0
    const [_, parent] = getSelectedTopParent()

    const shouldRenderSubMenu = hasSubMenu || parent || subMenuBottomContent || subMenuTopContent

    return (
        <View style={[styles.mainView, style]} testID={testID}>
            {mainMenuVisible && (
                <View style={[styles.mainMenuContainer, mainMenuContainerStyle]}>
                    {topContent && topContent}
                    <View>
                        <FlatList
                            testID="mainMenuItem"
                            data={menuData}
                            keyExtractor={(item) => `item-${item.id}`}
                            renderItem={(item: any) => renderMainMenu(item)}
                        />
                    </View>
                    {bottomContent && bottomContent}
                </View>
            )}
            {shouldRenderSubMenu && (
                <View style={[styles.subMenuContainer, subMenuContainerStyle]}>
                    <Text style={[styles.subMenuTitle, subMenuTitleStyle]} testID="subMenuTitle">
                        {selectedMenu?.children ? selectedMenu.name : parent?.name}
                    </Text>

                    <ScrollView nestedScrollEnabled={true} showsVerticalScrollIndicator={false}>
                        {subMenuTopContent && subMenuTopContent}
                        <FlatList
                            data={selectedMenu?.children || parent?.children}
                            testID="subMenuItem"
                            keyExtractor={(item) => `item-${item.id}`}
                            renderItem={null}
                            extraData={[iconData, selectedId]}
                            contentContainerStyle={subMenuListStyle}
                            CellRendererComponent={({ item }) => {
                                return renderSubMenu({ item })
                            }}
                        />
                        {subMenuBottomContent && subMenuBottomContent}
                    </ScrollView>
                </View>
            )}
        </View>
    )
}

export default SideMenu

