import { StyleSheet } from 'react-native'
import { useTheme } from '../../ThemeProvider/ThemeProvider'
import COLORS from '../../../utils/colors'

export const useStyles = () => {
    const { theme } = useTheme()

    return StyleSheet.create({
        mainView: {
            padding: theme?.spacing?.padding ?? 20,
        },
        mainContainer: {
            backgroundColor: theme?.colors?.background || COLORS.COLOR_WHITE,
            borderColor: theme?.colors?.border || '#E2E8F0',
            padding: theme?.spacing?.padding || 24,
            marginVertical: theme?.spacing?.margin || 10,
            borderWidth: theme?.spacing?.borderWidth || 1,
        },
        subView: {
            flexDirection: 'row',
            justifyContent: 'space-between',
            alignItems: 'center',
        },
        titleTextStyle: {
            fontSize: theme?.typography?.size?.['2xl']?.fontSize || 24,
        },
        detailsTextStyle: {
            fontSize: theme?.typography?.size?.base?.fontSize || 16,
            color: theme?.colors?.text || '#475569',
        },
        detailViewStyle: {
            marginTop: theme?.spacing?.margin ?? 10,
        },
        iconStyle: {
            width: 20,
            height: 20,
        },
    })
}
