import React from 'react'
import { SmallMediumCardProps } from './interface'

export const SmallMediumCardBase: React.FC<SmallMediumCardProps> = ({
    topIcon,
    image,
    children,
}) => {
    return (
        <>
            {topIcon && topIcon}
            {image && image}
            {children}
        </>
    )
}
