import React from 'react';
import { Pressable, View, Text } from 'react-native';
import CONSTANTS from '../../utils/constants';
import COLORS from '../../utils/colors';
import { createStyles } from './styles';
import STRINGS from '../../utils/strings';
import { useTheme } from '../ThemeProvider/ThemeProvider';
import { BuilderTheme } from '../ThemeProvider/themes';

export interface SystemMsgProps {
    msgType?: 'error' | 'warning' | 'info';
    systemMsg: string;
    rightIcon?: React.ReactNode;
    containerStyle?: object;
    textContainerStyle?: object;
    iconContainerStyle?: object;
    maxlength?: number;
    onPressIcon?: () => void;
    testID?: string;
}

export const getBackgroundColor = (msgType: 'error' | 'warning' | 'info', theme?: BuilderTheme) => {
    switch (msgType) {
        case CONSTANTS.ERROR:
            return theme?.colors?.error || COLORS.COLOR_ERROR_BGD;
        case CONSTANTS.WARNING:
            return theme?.colors?.warning || COLORS.COLOR_WARNING;
        case CONSTANTS.INFO:
            return theme?.colors?.info || COLORS.COLOR_INFO;
        default:
            return theme?.colors?.info || COLORS.COLOR_INFO;
    }
}

export const getColor = (msgType: 'error' | 'warning' | 'info', theme?: BuilderTheme) => {
    switch (msgType) {
        case CONSTANTS.ERROR:
            return theme?.colors?.error || COLORS.COLOR_RED;
        case CONSTANTS.WARNING:
            return theme?.colors?.warning || COLORS.COLOR_WARNING_BORDER;
        case CONSTANTS.INFO:
            return theme?.colors?.info || COLORS.COLOR_PURPLE;
        default:
            return theme?.colors?.info || COLORS.COLOR_PURPLE;
    }
}

const SystemMsg: React.FC<SystemMsgProps> = ({
    msgType = 'info',
    systemMsg,
    rightIcon,
    containerStyle,
    textContainerStyle,
    iconContainerStyle,
    maxlength = 60,
    onPressIcon,
    testID,
}) => {
    const { theme } = useTheme();
    const styles = createStyles(theme);

    return (
        <View
            style={[
                styles.container,
                { backgroundColor: getBackgroundColor(msgType, theme) },
                { borderLeftColor: getColor(msgType, theme) },
                containerStyle,
            ]}
            testID={testID}
        >
            <Text
                style={[
                    styles.textContainer,
                    { color: getColor(msgType, theme), fontFamily: theme?.typography?.fontFamily || 'Arial' },
                    textContainerStyle,
                ]}
            >
                {systemMsg.length < maxlength
                    ? systemMsg
                    : `${systemMsg.substring(0, maxlength)}...`}
            </Text>

            {rightIcon && (
                <Pressable
                    style={[styles.iconContainer, iconContainerStyle]}
                    testID={STRINGS.SYSTEM_MSG_ICON}
                    onPress={() => onPressIcon && onPressIcon()}
                >
                    {rightIcon}
                </Pressable>
            )}
        </View>
    );
};

export default SystemMsg;
