import React, { useState, FC } from 'react';
import { Pressable, Text, Image } from 'react-native';
import { SIZE, createStyles } from './styles';
import { IAvatar } from './interface';
import { useTheme } from '../ThemeProvider/ThemeProvider';

export interface AvatarProps extends IAvatar {}

const Avatar: FC<AvatarProps> = (props) => {
    const [hover, setHover] = useState(false);
    const { theme } = useTheme();
    const styles = createStyles(theme);

    const renderContent = () => {
        const { image, name, textStyle, size } = props;

        let fontSize = size ? Number(size) / 3 : 0;
        if (image) {
            return image;
        } else if (name) {
            return (
                <Text
                    style={[styles.text, textStyle, { fontSize }]}
                    testID={props.testIDText}
                >
                    {name.charAt(0)}
                </Text>
            );
        } else {
            return null;
        }
    };

    const {
        edit,
        onPress,
        onPressEdit,
        size,
        backgroundColor,
        style,
        testIDContainer,
        testIDEdit,
        editIcon,
        editText = 'Edit Picture',
        ...otherPressableProps
    } = props;

    const _backgroundColor = backgroundColor ? { backgroundColor } : {};
    const _size = size ? { height: Number(size), width: Number(size) } : {};
    const mainSize: any = size || SIZE;
    let fontSize = mainSize / 3;

    return (
        <Pressable
            onPress={onPress}
            style={[styles.container, _backgroundColor, style, _size]}
            testID={testIDContainer}
            {...otherPressableProps}
        >
            {renderContent()}
            {(edit || hover) && (
                <Pressable
                    style={styles.overlay}
                    testID={testIDEdit}
                    onPress={onPressEdit}
                >
                    {editIcon || (
                        <Image
                            source={{ uri: 'https://i.imgur.com/aD42TN5.png' }}
                            style={{
                                height: fontSize / 3,
                                width: fontSize / 3,
                                marginBottom: 4,
                            }}
                        />
                    )}
                    <Text style={{ fontSize: fontSize / 3, color: 'white' }}>
                        {editText}
                    </Text>
                </Pressable>
            )}
        </Pressable>
    );
};

export default Avatar;
