import { StyleSheet } from 'react-native';
import COLORS from '../../utils/colors';
import { BuilderTheme } from '../ThemeProvider/themes';

const createStyles = (theme: BuilderTheme) => StyleSheet.create({
    container: {
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'center',
        width: '100%',
    },
    subContainer: {
        flexDirection: 'row',
        alignContent: 'center',
        alignItems: 'center',
        justifyContent: 'center',
        flex: 1,
    },
    pageButton: {
        marginHorizontal: theme?.spacing?.margin ?? 5,
        padding: theme?.spacing?.padding ?? 5,
        borderRadius: theme?.spacing?.borderRadius ?? 5,
    },
    pageText: {
        color: theme?.colors?.text ?? COLORS.COLOR_LIGHTBLACK,
        textDecorationLine: 'underline',
    },
    ellipsisText: {
        color: theme?.colors?.text ?? COLORS.COLOR_LIGHTBLACK,
        textDecorationLine: 'none',
    },
    activePageText: {
        fontWeight: theme?.typography?.fontWeight?.bold ?? '700',
        color: theme?.colors?.primary ?? COLORS.COLOR_HIGHLIGHTBLUE,
    },
    totalTextView: {
        alignSelf: 'center',
        color: theme?.colors?.text ?? COLORS.COLOR_LIGHTBLACK,
    },
    icon: {
        width: 18,
        height: 18,
        tintColor: theme?.colors?.disabled ?? COLORS.COLOR_DISABLE_GREY,
    },
    paginationDataContainer: {
        flexDirection: 'row',
        justifyContent: 'flex-end',
        alignItems: 'center',
    },
});

export default createStyles;
