import { StyleSheet } from 'react-native';
import COLORS from '../../../utils/colors';
import { BuilderTheme } from '../../ThemeProvider/themes';

const createStyles = (theme?: BuilderTheme) => {
  return StyleSheet.create({
    mainContainer: {
      width: '100%',
      flexShrink: 1,
      backgroundColor: theme?.colors?.background ?? '#fff',
      padding: theme?.spacing?.padding ?? 16,
    },
    container: {
      width: '100%',
      flexShrink: 1,
    },
    contentStyle: {
      paddingHorizontal: theme?.spacing?.padding ?? 5,
    },
    navigationHeader: {
      width: '100%',
      flexShrink: 1,
      alignItems: 'center',
      flexDirection: 'row',
      justifyContent: 'space-between',
      marginBottom: 0,
      marginTop: theme?.spacing?.margin ?? 6,
    },
    headerTitle: {
      fontSize: theme?.typography?.size?.lg?.fontSize ?? 18,
      fontWeight: theme?.typography?.fontWeight?.bold ?? 'bold',
      color: theme?.colors?.text ?? '#374151',
    },
    navButton: {
      padding: theme?.spacing?.padding ?? 8,
    },
    navButtonText: {
      fontSize: theme?.typography?.size?.['2xl']?.fontSize ?? 24,
      color: theme?.colors?.primary ?? '#6366f1',
    },
    dateInputContainer: {
      marginBottom: theme?.spacing?.margin ?? 16,
    },
    dateInput: {
      flex: 1,
      padding: theme?.spacing?.padding ?? 12,
      fontSize: theme?.typography?.size?.base?.fontSize ?? 16,
      color: theme?.colors?.text ?? '#374151',
    },
    calendarIcon: {
      padding: theme?.spacing?.padding ?? 12,
      borderLeftWidth: theme?.spacing?.borderWidth ?? 1,
      borderLeftColor: theme?.colors?.border ?? '#E5E7EB',
    },
    calendar: {
      borderColor: theme?.colors?.border ?? '#E5E7EB',
      borderRadius: theme?.spacing?.borderRadius ?? 8,
    },
    monthGridContainer: {
      flex: 1,
    },
    monthGrid: {
      flexDirection: 'row',
      flexWrap: 'wrap',
      justifyContent: 'space-between',
      padding: theme?.spacing?.padding ?? 8,
    },
    monthButton: {
      width: '30%',
      padding: theme?.spacing?.padding ?? 12,
      marginBottom: theme?.spacing?.margin ?? 12,
      borderRadius: theme?.spacing?.borderRadius ?? 3,
      borderColor: theme?.colors?.border ?? COLORS.COLOR_LIGHTGRAY,
      borderWidth: theme?.spacing?.borderWidth ?? 1,
      alignItems: 'center',
    },
    selectedMonth: {
      backgroundColor: theme?.colors?.secondaryBackground ?? COLORS.COLOR_SEGMNTD,
    },
    monthText: {
      color: theme?.colors?.text ?? COLORS.COLOR_LISTTEXT,
      fontSize: theme?.typography?.size?.sm?.fontSize ?? 14,
    },
    selectedMonthText: {
      color: theme?.colors?.hover ?? COLORS.COLOR_HIGHLIGHTBLUE,
    },
    renderArrowStyle: {},
  });
};

export default createStyles;
