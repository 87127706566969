// src/components/DataTable/style.ts
import { StyleSheet } from 'react-native'
import {
    horizontalScale,
    moderateScale,
    verticalScale,
} from '../../utils/screenRatio'
import { BuilderTheme } from '../ThemeProvider/themes'

const createStyles = (theme: BuilderTheme) => {
    return StyleSheet.create({
        coloumnView: {
            flexDirection: 'row',
        },
        scrollContainer: {
            overflow: 'scroll',
            width: '100%',
        },
        container: {
            flex: 1,
            padding: theme?.spacing?.padding || 10,
            backgroundColor: theme?.colors?.background || '#F8FAFC',
            width: '100%',
            minWidth: 'fit-content',
        },
        header: {
            flex: 1,
            flexDirection: 'row',
            paddingBottom: theme?.spacing?.padding || verticalScale(5),
            borderBottomColor: theme?.colors?.border || '#CBD5E1',
            borderBottomWidth: 1,
        },
        tableRow: {
            flexDirection: 'row',
            borderBottomWidth: 0.2,
            borderColor: theme?.colors?.border || '#CBD5E1',
            backgroundColor: theme?.colors?.background || 'white',
        },
        headerText: {
            flex: 1,
            color: theme?.colors?.text || '#475569',
            fontSize: theme?.typography?.size?.sm?.fontSize || 14,
            minWidth: 75,
            overflow: 'hidden',
            padding: theme?.spacing?.padding || 12,
        },
        titleStyle: {
            color: theme?.colors?.text || '#64748B',
            fontSize: theme?.typography?.size?.xs?.fontSize || 12,
            marginLeft: theme?.spacing?.margin || horizontalScale(10),
        },
        textStyle: {
            borderBottomWidth: theme?.spacing?.borderWidth || 1,
            borderBottomColor: theme?.colors?.border || '#CBD5E1',
            height: 42,
            justifyContent: 'center',
            flexWrap: 'wrap',
        },
        headerDataContainerStyle: {
            flexDirection: 'row',
        },
        autoWidthCell: {
            flexBasis: 'auto',
            flexGrow: 0,
            flexShrink: 0,
        },
        mobileTableContent: {
            width: '100%',
        },
    })
}

export default createStyles
