import React, { FC, ReactNode, useEffect } from 'react';
import {
    View,
    Text,
    ViewStyle,
    TextStyle,
    StyleSheet,
    Platform,
} from 'react-native';
import { useTheme } from '../ThemeProvider/ThemeProvider';
import COLORS from '../../utils/colors';
import { BuilderTheme } from '../ThemeProvider/themes';

export type ToastProps = {
    message?: string;
    textStyle?: TextStyle;
    containerStyle?: ViewStyle;
    isVisible?: boolean;
    children?: ReactNode;
    /* Duration in milliseconds */
    duration?: number;
    onClose?: () => void;
    testID?: string;
    messageTestID?: string;
};

const Toast: FC<ToastProps> = (props) => {
    const {
        message,
        containerStyle,
        textStyle,
        isVisible,
        children,
        duration,
        onClose,
        testID,
        messageTestID,
    } = props;

    const { theme } = useTheme();
    const styles = createStyles(theme);

    useEffect(() => {
        if (isVisible) {
            const timer = setTimeout(() => {
                onClose?.();
            }, duration || 3000);

            return () => clearTimeout(timer);
        }
    }, [isVisible, onClose, duration]);

    if (!isVisible) return null;

    return (
        <View style={[styles.container, containerStyle]} testID={testID}>
            {React.isValidElement(children) ? (
                children
            ) : (
                <Text
                    style={[styles.textStyle, textStyle]}
                    testID={messageTestID}
                >
                    {message}
                </Text>
            )}
        </View>
    );
};

const createStyles = (theme?: BuilderTheme) =>
    StyleSheet.create({
        container: {
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: 'center',
            paddingHorizontal: theme?.spacing?.padding ?? 16,
            paddingVertical: theme?.spacing?.padding ?? 8,
            borderRadius: theme?.spacing?.borderRadius ?? 8,
            backgroundColor: theme?.colors?.background ?? 'white',
            ...(Platform.OS === 'web'
                ? {
                      boxShadow:
                          '0px 8px 32px rgba(0, 0, 0, 0.06), 0px 4px 8px rgba(0, 0, 0, 0.03)',
                  }
                : {
                      shadowColor: theme?.colors?.shadow ?? '#171717',
                      shadowOffset: { width: -2, height: 4 },
                      shadowOpacity: 0.2,
                      shadowRadius: 3,
                  }),
        },
        textStyle: {
            color: theme?.colors?.text ?? COLORS.COLOR_LIGHTBLACK,
        },
    });

export default Toast;
