import { StyleSheet, Platform } from 'react-native'
import { BuilderTheme } from '../ThemeProvider/themes'
import COLORS from '../../utils/colors'

const createStyles = (theme: BuilderTheme) => StyleSheet.create({
    container: {
        borderWidth: theme?.spacing?.borderWidth ?? 1,
        flexDirection: 'row',
        alignItems: 'center',
        backgroundColor: theme?.colors?.background || COLORS.COLOR_WHITE,
        borderRadius: theme?.spacing?.borderRadius || 8,
        padding: theme?.spacing?.padding || 8,
    },
    input: {
        flex: 1,
        fontSize: theme?.typography?.size?.base?.fontSize || 16,
        fontWeight: theme?.typography?.fontWeight?.normal || '400',
        color: theme?.colors?.text || COLORS.COLOR_COOLGRAY,
        ...(Platform.OS === 'web' && {
            outlineColor: theme?.colors?.primary || COLORS.COLOR_HIGHLIGHTBLUE,
        }),
        fontFamily: theme?.typography?.fontFamily || 'Arial',
    },
    error: {
        fontSize: theme?.typography?.size?.sm?.fontSize || 14,
        fontWeight: theme?.typography?.fontWeight?.normal || '400',
        color: theme?.colors?.error || COLORS.COLOR_RED,
        marginTop: theme?.spacing?.margin || 2,
    },
    characterLimit: {
        display: 'flex',
        justifyContent: 'flex-end',
        marginTop: theme?.spacing?.margin || 2,
    },
    label: {
        fontSize: theme?.typography?.size?.base?.fontSize || 16,
        fontWeight: theme?.typography?.fontWeight?.bold || '700',
        fontFamily: theme?.typography?.fontFamily || 'Arial',
        color: theme?.colors?.secondary || COLORS.COLOR_SUBTEXT,
    },
    labelContainer: {
        marginBottom: theme?.spacing?.margin || 8,
        marginHorizontal: theme?.spacing?.margin || 4,
    },
})

export default createStyles
