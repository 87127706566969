import { StyleSheet } from 'react-native';
import { BuilderTheme } from '../ThemeProvider/themes';


const createStyles = (theme: BuilderTheme) => {
    return StyleSheet.create({
        mainView: {
            height: 50,
            backgroundColor: theme?.colors?.background || '#fff',
            flexDirection: 'row',
            alignItems: 'center',
            width: '100%',
            top: 0,
            position: 'absolute',
        },
        shadowProp: {
            shadowOffset: { width: -2, height: 4 },
            shadowColor: theme?.colors?.shadow || '#171717',
            shadowOpacity: 0.2,
            shadowRadius: 3,
            borderBottomRightRadius: theme?.spacing?.borderRadius || 24,
        },
        headerText: {
            fontWeight: theme?.typography?.fontWeight?.bold || '700',
            fontSize: theme?.typography?.size?.sm?.fontSize || 14,
            color: theme?.colors?.text || '#000000',
            // fontFamily: theme?.typography?.h1?.fontFamily || 'Arial',
        },
        rightIcon: {
            height: theme?.icons?.size || 18,
            width: theme?.icons?.size || 18,
            tintColor: theme?.colors?.disabled || '#bdbdbd',
        },
        // Add other styles here...
        leftIcon: {
            height: theme?.icons?.size || 18,
            width: theme?.icons?.size || 18,
            tintColor: theme?.colors?.disabled || '#bdbdbd',
        },
        btnTextStyle: {
            // fontFamily: theme?.typography?.p?.fontFamily || 'Inter-Medium',
            fontSize: theme?.typography?.size?.sm?.fontSize || 14,
            color: theme?.colors?.primary || '#1976d2',
        },
        leftIconView: {
            flex: 0.2,
            alignItems: 'flex-start',
            paddingLeft: theme?.spacing?.padding || 22,
        },
        rightIconView: {
            flex: 0.2,
            alignItems: 'flex-end',
            paddingRight: theme?.spacing?.padding || 22,
        },
        headerIcon: {
            height: theme?.icons?.size || 18,
            width: theme?.icons?.size || 18,
            tintColor: theme?.colors?.text || '#000000',
        },
        headerCenterView: {
            flex: 0.6,
            alignItems: 'center',
            flexDirection: 'row',
            justifyContent: 'center',
        },
        headerIconView: {
            marginRight: theme?.spacing?.margin || 5,
        },
    });
};

export default createStyles;
