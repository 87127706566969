import React from 'react'
import { Pressable, Platform, View, Text, Image } from 'react-native'
import {
    Menu,
    MenuOptions,
    MenuOption,
    MenuTrigger,
} from 'react-native-popup-menu'
import COLORS from '../../utils/colors'
import STRINGS from '../../utils/strings'
import { createStyles, createNestedOptionsStyles } from './styles'
import { View as RnView } from 'react-native-web'
import { useTheme } from '../ThemeProvider/ThemeProvider'
import { BuilderTheme } from '../ThemeProvider/themes'

export interface ContextMenuProps {
    options: {
        id: any
        label: string
        leftIcon?: React.ReactNode
        rightIcon?: React.ReactNode
    }[]
    nestedOptions?: {
        id: any
        label: string
    }[]
    onPress: (mainOption: any, nestedOption?: any) => void
    OptionsTextStyle?: {}
    Icon?: React.ReactNode
    IconHighlighted?: React.ReactNode
    IconLeft?: React.ReactNode
    IconRight?: React.ReactNode
    popupPosition?: 'left' | 'right'
    menuOptionStyles?: object
    menuProviderStyle?: object
    submenuContainerStyle?: object
    optionsContainerStyle?: object
    testID?: string
    initialExpanded?: boolean
    initialSelection?: number
}

export const getColorByText = (label: string, theme?: BuilderTheme): string => {
    switch (label) {
        case 'Delete':
            return theme?.colors?.secondary ?? COLORS.COLOR_RED
        default:
            return theme?.colors?.text ?? COLORS.COLOR_COOLGRAY
    }
}

export const ContextMenu: React.FC<ContextMenuProps> = (props) => {
    const { theme } = useTheme()
    const styles = createStyles(theme)
    const nestedOptionsStyles = createNestedOptionsStyles(theme)

    const containerRef = React.useRef<RnView>(null)
    const menuRef = React.useRef<RnView>(null)
    const [isMenuOpen, setIsMenuOpen] = React.useState<boolean>(
        props.initialExpanded || false,
    )
    const [selectedOption, setSelectedOption] = React.useState<number | null>(
        props.initialSelection || null,
    )
    const [isSubmenuOpen, setIsSubmenuOpen] = React.useState<boolean>(
        !!(props.initialExpanded && props.nestedOptions?.length),
    )

    const [menuWidth, setMenuWidth] = React.useState(0)

    const handleMenuOpen = () => {
        setIsMenuOpen(true)
        setIsSubmenuOpen(false)
    }

    const handleMenuClose = () => {
        setIsMenuOpen(false)
        setIsSubmenuOpen(false)
        setSelectedOption(null)
    }

    const handleOptionPress = (option: any) => {
        setSelectedOption(option.id)
        setIsSubmenuOpen(true)
        props.onPress(option)
    }

    const handleWebClickOutside = (event: any) => {
        if (menuRef.current?.contains(event.target)) return

        if (
            containerRef.current &&
            !containerRef.current.contains(event.target)
        ) {
            handleMenuClose()
        }
    }

    React.useEffect(() => {
        if (Platform.OS === 'web') {
            document.addEventListener('mouseup', handleWebClickOutside)
        }
        return () => {
            if (Platform.OS === 'web') {
                document.removeEventListener('mouseup', handleWebClickOutside)
            }
        }
    }, [])

    const getBackgroundColor = (
        isSubmenuOpen?: boolean,
        selectedOption?: number,
        option?: any,
    ) => {
        if (
            isSubmenuOpen &&
            selectedOption === option.id &&
            option.rightIcon !== ''
        ) {
            return theme?.colors?.hover ?? COLORS.COLOR_LIGHTGRAY
        } else if (selectedOption === option.id) {
            return theme?.colors?.background ?? COLORS.COLOR_LIGHTPINK
        } else {
            return ''
        }
    }

    const getPositionStyle = () => {
        const { popupPosition } = props
        return popupPosition === 'right'
            ? { ...styles.itemContainer, left: menuWidth / 2 }
            : { ...styles.itemContainer, right: menuWidth / 2 }
    }

    const renderOptions = () => {
        const {
            options,
            nestedOptions,
            OptionsTextStyle,
            menuOptionStyles,
            submenuContainerStyle,
            onPress,
        } = props

        return options.map((option) => {
            const { id, label, rightIcon, leftIcon } = option
            const mainOption = { id, label }

            return (
                <MenuOption
                    key={id}
                    style={[
                        styles.menuOptionStyle,
                        {
                            backgroundColor: getBackgroundColor(
                                isSubmenuOpen,
                                selectedOption,
                                option,
                            ),
                        },
                        menuOptionStyles,
                    ]}
                >
                    <Pressable
                        style={[styles.optionContainer]}
                        onPress={() => {
                            handleOptionPress(option)
                        }}
                    >
                        <View style={styles.imageView}>
                            <View style={{ width: 6 }}>
                                {props.IconLeft || leftIcon || null}
                            </View>
                            <Text
                                style={[
                                    styles.optionText,
                                    { color: getColorByText(label, theme) },
                                    OptionsTextStyle,
                                ]}
                            >
                                {label}
                            </Text>
                        </View>
                        {props.IconRight || rightIcon || null}
                    </Pressable>
                    {selectedOption === id && isSubmenuOpen && (
                        <View
                            style={[
                                styles.submenuContainer,
                                submenuContainerStyle,
                            ]}
                        >
                            <MenuOptions customStyles={nestedOptionsStyles}>
                                {nestedOptions?.map((nestedOption) => {
                                    return (
                                        <MenuOption key={nestedOption.id}>
                                            <Pressable
                                                style={styles.optionContainer}
                                                onPress={() =>
                                                    onPress &&
                                                    onPress(
                                                        mainOption,
                                                        nestedOption,
                                                    )
                                                }
                                            >
                                                <Text
                                                    style={[
                                                        styles.optionText,
                                                        OptionsTextStyle,
                                                    ]}
                                                >
                                                    {nestedOption.label}
                                                </Text>
                                            </Pressable>
                                        </MenuOption>
                                    )
                                })}
                            </MenuOptions>
                        </View>
                    )}
                </MenuOption>
            )
        })
    }

    const { Icon, IconHighlighted, testID } = props

    return (
        <Menu
            onOpen={handleMenuOpen}
            onClose={handleMenuClose}
            opened={isMenuOpen}
            style={styles.container}
        >
            <MenuTrigger
                testID={testID}
                onPress={() => {
                    if (Platform.OS === 'web') {
                        document.removeEventListener(
                            'mouseup',
                            handleWebClickOutside,
                        )
                    }
                    setIsMenuOpen(!isMenuOpen)
                    if (Platform.OS === 'web') {
                        document.addEventListener(
                            'mouseup',
                            handleWebClickOutside,
                        )
                    }
                }}
            >
                <View
                    testID={STRINGS.CONTEXTMENU_TESTID}
                    ref={menuRef}
                    style={[
                        styles.highlightedIconView,
                        {
                            backgroundColor: isMenuOpen
                                ? COLORS.COLOR_LIGHTGRAY
                                : COLORS.COLOR_WHITE,
                        },
                    ]}
                    onLayout={(e) => {
                        setMenuWidth(e.nativeEvent.layout.width)
                    }}
                >
                    {Icon ? (
                        isMenuOpen ? (
                            IconHighlighted
                        ) : (
                            Icon
                        )
                    ) : (
                        <Image
                            resizeMode={'contain'}
                            style={[
                                styles.icon,
                                isMenuOpen && styles.highlightedIcon,
                            ]}
                            source={{
                                uri: 'https://i.imgur.com/fEsFjVT.png',
                            }}
                        />
                    )}
                </View>
            </MenuTrigger>
            {/* To silence error */}
            <MenuOptions />
            {/* View wrapper is required to enable overflow: visible */}
            <View style={{ overflow: 'visible' }}>
                <MenuOptions
                    optionsContainerStyle={{
                        overflow: 'visible',
                        backgroundColor: 'green',
                    }}
                >
                    {!isMenuOpen ? null : (
                        <View
                            ref={containerRef}
                            style={[
                                getPositionStyle(),
                                styles.optionsContainer,
                                props.optionsContainerStyle,
                            ]}
                        >
                            {renderOptions()}
                        </View>
                    )}
                </MenuOptions>
            </View>
        </Menu>
    )
}
