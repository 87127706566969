import React, { useMemo } from 'react'
import { StepData, StepState } from './interface'
import { Image, Pressable, Text, View } from 'react-native'
import { createStyles } from './styles'
import COLORS from '../../utils/colors'
import LinearGradientComponent from '../LinearGradient'
import StepDoneImage from '../../assets/images/step_done.png'
import { useTheme } from '../ThemeProvider/ThemeProvider'

const Step: React.FC<StepData> = (props) => {
    const {
        step,
        text,
        stepContainer,
        stepIconContainer,
        stepTextStyle,
        textStyle,
        gradientColors,
        id,
        icon,
        onPress,
        state,
        testID,
    } = props

    const { theme } = useTheme()
    const styles = createStyles(theme)

    const getDefaultTextSyle = (state?: StepState) => {
        switch (state) {
            case 'active':
                return styles.activeText
            default:
                return styles.futureText
        }
    }

    const getDefaultStepIconContainerColor = (state?: StepState) => {
        switch (state) {
            case 'active':
                return COLORS.COLOR_LIGHTBLACK
            case 'complete':
                return COLORS.COLOR_COMPLETED
            case 'future':
                return COLORS.COLOR_GRAY_PLACEHOLDER
            default:
                return COLORS.COLOR_GRAY_PLACEHOLDER
        }
    }

    const flattenStyles = (input: any): Record<string, any> => {
        if (!Array.isArray(input)) {
            return input
        }

        // Flatten the array to handle nested arrays
        const flattenedArray = input.flat(Infinity)

        const mergeStyles = (
            accumulatedStyles: Record<string, any>,
            currentStyle: any,
        ): Record<string, any> => {
            if (
                typeof currentStyle === 'object' &&
                !Array.isArray(currentStyle)
            ) {
                return { ...accumulatedStyles, ...currentStyle }
            }
            return accumulatedStyles
        }

        return flattenedArray.reduce(mergeStyles, {})
    }

    const renderIconOrText = useMemo(() => {
        if (!!icon) return icon

        if (state === 'complete')
            return <Image source={StepDoneImage} style={[styles.imageStyle]} />

        return <Text style={[styles.step, stepTextStyle]}>{step}</Text>
    }, [icon, step, styles, stepTextStyle])

    return (
        <Pressable
            style={[styles.stepContainer, stepContainer]}
            onPress={() => onPress(id)}
            testID={testID + '-' + id}
        >
            {gradientColors?.length ? (
                <LinearGradientComponent
                    colors={gradientColors}
                    style={flattenStyles([
                        styles.stepIconContainer,
                        stepIconContainer,
                    ])}
                >
                    {renderIconOrText}
                </LinearGradientComponent>
            ) : (
                <View
                    style={[
                        styles.stepIconContainer,
                        {
                            backgroundColor:
                                getDefaultStepIconContainerColor(state),
                        },
                        stepIconContainer,
                    ]}
                    {...props}
                >
                    {renderIconOrText}
                </View>
            )}

            {text && (
                <Text style={[getDefaultTextSyle(state), textStyle]}>
                    {text}
                </Text>
            )}
        </Pressable>
    )
}

export default Step
