import React, { FC, useCallback } from 'react'

import Carousel, { CarouselProps } from '../Carousel/Carousel'
import { StyleProp, StyleSheet, View, ViewStyle } from 'react-native'
import { Typography, TypographyProps } from '../Typography/Typography'
import { BuilderTheme } from '../ThemeProvider/themes'
import { useTheme } from '../ThemeProvider/ThemeProvider'

export interface CarouselCardProps extends Omit<CarouselProps, 'renderItem'> {
    items: Item[]
    titleProps?: Omit<TypographyProps, 'children'>
    descriptionProps?: Omit<TypographyProps, 'children'>
    textContainerStyle?: StyleProp<ViewStyle>
    cardContainerStyle?: StyleProp<ViewStyle>
}

export interface Item {
    title: string
    description: string
    image?: React.ReactNode
}

export const CarouselCard: FC<CarouselCardProps & {}> = (props) => {
    const {
        items,
        titleProps,
        descriptionProps,
        textContainerStyle,
        cardContainerStyle,
        ...carouselProps
    } = props

    const { theme } = useTheme();
    const styles = createStyles(theme);

    return (
        <Carousel
            CellRendererComponent={useCallback(
                (props) => (
                    <View {...props} style={styles.cellRenderer} />
                ),
                [],
            )}
            maxItemsPerView={1}
            paginationType={'arrows'}
            items={items}
            contentContainerStyle={{
                width: '100%',
            }}
            renderItem={(item, index, dotPagination) => {
                return (
                    <View style={[styles.card, cardContainerStyle]}>
                        {item.image ? item.image : null}
                        {dotPagination}
                        <View style={[textContainerStyle]}>
                            <Typography
                                variant={'2xl'}
                                style={styles.text}
                                {...titleProps}
                            >
                                {item.title}
                            </Typography>
                            <Typography
                                variant={'sm'}
                                style={{ ...styles.text }}
                                {...descriptionProps}
                            >
                                {item.description}
                            </Typography>
                        </View>
                    </View>
                )
            }}
            {...carouselProps}
        />
    )
}

const createStyles = (theme: BuilderTheme) => StyleSheet.create({
    card: {
        borderRadius: theme?.spacing?.borderRadius ?? 8,
        alignSelf: 'center',
        height: 'auto',
        alignItems: 'center',
        width: '100%',
    },
    text: { textAlign: 'center' },
    cellRenderer: {
        width: '100%',
        flexDirection: 'column',
        justifyContent: 'center',
    },
})
