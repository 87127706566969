import { StyleSheet } from 'react-native';
import COLORS from '../../utils/colors';
import { BuilderTheme } from '../ThemeProvider/themes';

const createStyles = (theme: BuilderTheme) => StyleSheet.create({
    container: {
        padding: theme?.spacing?.padding ?? 10,
        flexDirection: 'row',
        alignItems: 'center',
        backgroundColor: theme?.colors?.background ?? 'transparent',
    },
    textView: {
        flexDirection: 'column',
        flex: 1,
    },
    text: {
        fontSize: theme?.typography?.size?.base?.fontSize ?? 16,
        fontWeight: theme?.typography?.fontWeight?.normal ?? '400',
        color: theme?.colors?.text ?? COLORS.COLOR_LISTTEXT,
    },
    subText: {
        fontSize: theme?.typography?.size?.sm?.fontSize ?? 14,
        fontWeight: theme?.typography?.fontWeight?.normal ?? '400',
        color: theme?.colors?.text ?? COLORS.COLOR_SUBTEXT,
    },
    itemSeparator: {
        height: 1,
        backgroundColor: theme?.colors?.border ?? COLORS.COLOR_LIGHTGRAY,
        width: '100%',
    },
    column: {
        flexDirection: 'column',
        flex: 1,
    },
    row: {
        flexDirection: 'row',
        alignItems: 'center',
    },
    marginHorizontal: {
        marginHorizontal: theme?.spacing?.margin ?? 10,
    },
    iconRightContainer: {
        marginLeft: 'auto',
    },
    childrenContainer: {
        marginTop: theme?.spacing?.margin ?? 10,
    },
});

export default createStyles;
