import { StyleSheet } from 'react-native';
import { BuilderTheme } from '../ThemeProvider/themes';

export const createStyles = (theme: BuilderTheme) => StyleSheet.create({
    container: {
        height: 60,
        borderRadius: theme?.spacing?.borderRadius || 4,
        borderLeftWidth: theme?.spacing?.borderWidth || 4,
        width: 360,
        flexDirection: 'row',
        alignItems: 'center',
    },
    textContainer: {
        fontSize: theme?.typography?.size?.sm?.fontSize || 12,
        fontWeight: theme?.typography?.fontWeight?.normal || '400',
        paddingVertical: theme?.spacing?.padding || 16,
        paddingHorizontal: theme?.spacing?.padding || 12,
        fontFamily: theme?.typography?.fontFamily || 'Arial',
    },
    iconContainer: {
        justifyContent: 'center',
        alignItems: 'center',
    },
    rightIconStyle: {
        height: theme?.icons?.size / 1.5 || 16,
        width: theme?.icons?.size / 1.5 || 16,
    },
});
