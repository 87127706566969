import React, { useState } from 'react'
import {
    View,
    Text,
    ViewStyle,
    StyleProp,
    TextStyle,
} from 'react-native'
import { RadioButton, RadioButtonProps } from './RadioButton'
import { useTheme } from '../ThemeProvider/ThemeProvider'
import { BuilderTheme } from '../ThemeProvider/themes'

export type RadioButtonGroupProps = {
    title: string
    titleStyle?: StyleProp<ViewStyle | TextStyle>
    containerStyle?: StyleProp<ViewStyle>
    radioButtons: RadioButtonProps[]
    onSelectionChange?: (selectedIndex: number) => void
    testID?: string
}

const createStyles = (theme: BuilderTheme) => ({
    titleStyle: {
        paddingLeft: theme.spacing?.padding || 20,
        fontFamily: theme.typography?.fontFamily || 'Arial',
        fontSize: theme.typography?.size?.sm?.fontSize || 14,
        fontWeight: theme.typography?.fontWeight?.bold || 'bold',
        color: theme.colors?.text || '#808080',
    } as TextStyle,
})

export const RadioButtonGroup: React.FC<RadioButtonGroupProps> = (
    props: RadioButtonGroupProps,
) => {
    const {
        title,
        titleStyle,
        radioButtons,
        containerStyle,
        onSelectionChange,
        testID,
    } = props

    const { theme } = useTheme()
    const styles = createStyles(theme || {} as BuilderTheme)

    const [selectedIndex, setSelectedIndex] = useState<number>(() => {
        const selectedIndexInProps = radioButtons.findIndex(
            (radioButton) => radioButton.checked === true,
        )

        return selectedIndexInProps > -1 ? selectedIndexInProps : 0
    })

    return (
        <View style={containerStyle} testID={testID}>
            <Text style={[styles.titleStyle, titleStyle]}>{title}</Text>
            {radioButtons.map((radioButtonProps, index: number) => {
                return (
                    <RadioButton
                        {...radioButtonProps}
                        checked={selectedIndex === index}
                        onPress={() => {
                            setSelectedIndex(index)
                            if (onSelectionChange) {
                                onSelectionChange(index)
                            }
                        }}
                        key={`${radioButtonProps.title || ''}${index}`}
                    />
                )
            })}
        </View>
    )
}
