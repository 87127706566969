// src/components/Chips/themedStyles.ts
import { StyleSheet } from 'react-native'
import { BuilderTheme } from '../ThemeProvider/themes'
import COLORS from '../../utils/colors'

export const createStyles = (theme?: BuilderTheme) => StyleSheet.create({
    text: {
        alignSelf: 'center',
        fontSize: theme?.typography?.size?.base?.fontSize ?? 16,
        lineHeight: theme?.typography?.size?.base?.lineHeight ?? 24,
        fontWeight: theme?.typography?.fontWeight.bold ?? '700',
        marginHorizontal: theme?.spacing?.margin ?? 8,
        color: theme?.colors?.text ?? COLORS.COLOR_MEDIUMBLUE,
    },
    notActiveText: {
        color: theme?.colors?.disabled ?? COLORS.COLOR_DISABLE_GREY,
    },
    activeText: {
        color: theme?.colors?.disabled ?? COLORS.COLOR_DISABLE_GREY,
    },
    defaultChips: {
        justifyContent: 'center',
        alignItems: 'center',
        borderRadius: theme?.spacing?.borderRadius ?? 26,
        backgroundColor: theme?.colors?.background ?? 'transparent',
    },
    notActiveChips: {
        justifyContent: 'center',
        alignItems: 'center',
        borderRadius: theme?.spacing?.borderRadius ?? 26,
        backgroundColor: theme?.colors?.disabled ?? COLORS.COLOR_BTNGRAY,
    },
    activeChips: {
        justifyContent: 'center',
        alignItems: 'center',
        borderRadius: theme?.spacing?.borderRadius ?? 26,
        display: 'flex',
    },
})