import React, { useEffect, useMemo, useRef, useState, FC } from 'react';
import {
    StyleProp,
    ViewStyle,
    View,
    ScrollView,
    TextStyle,
    Text,
    Pressable,
    Animated,
    Dimensions,
    PressableProps,
} from 'react-native';
import { usePlatfomHook } from '../../../utils/getPlatformType';
import OutsideClickHandler from '../../../molecules/OutsideClickHandler';
import Button from '../../Button/Button';
import { Portal } from '@gorhom/portal';
import { useTheme } from '../../ThemeProvider/ThemeProvider';
import { StyleSheet } from 'react-native';
import { BuilderTheme } from 'src/components/ThemeProvider/themes';

export interface SideNavigationProps extends Omit<PressableProps, 'onPress'> {
    testID?: string;
    header?: string;
    headerTextStyle?: StyleProp<TextStyle>;
    containerStyle?: ViewStyle;
    contentContainerStyle?: ViewStyle;
    showResponsiveButton?: boolean;
    responsiveButton?: React.ReactNode;
    items: Array<SideNavigationItem>;
    onPress: (menuItem: any, subMenuItem: any) => any;
    closeHidenSideNavOnPress?: boolean; // Hide typo
    showResponsiveDrawerOverride?: boolean;
    showResponsiveDrawer?: boolean;
}

export interface SideNavigationItem {
    id: string;
    text: string;
    isSelected?: boolean;
    textStyle?: TextStyle;
    buttonStyle?: ViewStyle;
    isSelectedTextStyle?: TextStyle;
    isSelectedButtonStyle?: ViewStyle;
    subMenuExpanded?: boolean;
    hideSubMenuOnSelect?: boolean;
    items?: Array<SideNavigationItem>;
    containerStyle?: ViewStyle;
}

export const SideNavigation: FC<SideNavigationProps> = ({
    testID,
    containerStyle,
    contentContainerStyle,
    onPress,
    items,
    header,
    headerTextStyle,
    showResponsiveButton = true,
    responsiveButton,
    showResponsiveDrawerOverride = false,
    showResponsiveDrawer,
    closeHidenSideNavOnPress = true,
}) => {
    const { theme } = useTheme(); // Access theme
    const styles = createStyles(theme); // Get themed styles

    const [sideNavContainerWidth, setSideNavContainerWidth] = useState(0);
    const { screenType, isDesktop, isMobile, isTablet } = usePlatfomHook();
    const onLoad = useRef(false);
    const slideDrawerAnim = useRef(new Animated.Value(Dimensions.get('screen').width * -1)).current;

    const sideNavTop = parseInt((containerStyle?.top ?? 0).toString());

    useEffect(() => {
        if (onLoad.current) {
            adjustDrawer(false);
        }
    }, [showResponsiveDrawer]);

    const handleSideNavLayout = (event) => {
        if (!onLoad.current) {
            onLoad.current = true;
            Animated.timing(slideDrawerAnim, {
                toValue: event.nativeEvent.layout?.width * -1,
                duration: 100,
                useNativeDriver: false,
            }).start();
        }
        setSideNavContainerWidth(event.nativeEvent.layout?.width);
    };

    const adjustDrawer = (outSideClickHandler: boolean) => {
        const slideDrawerAnimValue = parseInt(JSON.stringify(slideDrawerAnim));
        if (outSideClickHandler && slideDrawerAnimValue === sideNavContainerWidth * -1) {
            return;
        }

        Animated.timing(slideDrawerAnim, {
            toValue: slideDrawerAnimValue === 0 ? sideNavContainerWidth * -1 : 0,
            duration: 150,
            useNativeDriver: false,
        }).start();
    };

    const handleOnPress = (item: SideNavigationItem, subItem?: SideNavigationItem) => {
        closeHidenSideNavOnPress && adjustDrawer(false);
        onPress?.(item, subItem);
    };

    const handleListItem = (item: SideNavigationItem) => {
        return (
            <View key={item.id} style={[item.containerStyle, styles.navigationContainerItem]}>
                <Pressable
                    testID={`${testID}-${item.id}-side-nav-nav-item`}
                    style={
                        item.isSelected
                            ? item.isSelectedButtonStyle
                            : item.buttonStyle
                    }
                    onPress={() => handleOnPress(item, undefined)}
                >
                    <Text
                        style={[
                            styles.navigationTextItem,
                            item.isSelected
                                ? {
                                    ...styles.isSelectedTextStyle,
                                    ...item.isSelectedTextStyle,
                                }
                                : item.textStyle,
                        ]}
                    >
                        {item.text}
                    </Text>
                </Pressable>
                {item?.items?.length && item.subMenuExpanded && (
                    <>
                        {item.items.map((subItem) => {
                            return (
                                <Pressable
                                    key={subItem.id}
                                    testID={`${testID}-${subItem.id}-side-nav-nav-sub-item`}
                                    style={
                                        subItem.isSelected
                                            ? subItem.isSelectedButtonStyle
                                            : subItem.buttonStyle
                                    }
                                    onPress={() => handleOnPress(item, subItem)}
                                >
                                    <Text
                                        style={[
                                            styles.subMenuNavigationTextItem,
                                            subItem.isSelected
                                                ? {
                                                    ...styles.isSelectedSubMenuTextStyle,
                                                    ...subItem.isSelectedTextStyle,
                                                }
                                                : subItem.textStyle,
                                        ]}
                                    >
                                        {subItem.text}
                                    </Text>
                                </Pressable>
                            );
                        })}
                    </>
                )}
            </View>
        );
    };

    const getSideNavContent = () => {
        if (isDesktop && showResponsiveDrawerOverride) {
            return null;
        }
        const sideNavStyles = !showResponsiveDrawerOverride
            ? [styles.container, containerStyle]
            : styles.container;
        return (
            <ScrollView
                style={sideNavStyles}
                contentContainerStyle={[
                    styles.contentContainerStyle,
                    contentContainerStyle,
                ]}
                testID={`${testID}-side-nav`}
            >
                {!!header && <Text style={[styles.header, headerTextStyle]}>{header}</Text>}
                <>{items?.map(handleListItem)}</>
            </ScrollView>
        );
    };

    const getHiddenSideNavContent = () => {
        if (isDesktop) {
            return null;
        }
        const renderResponsiveButton = () => {
            if (!showResponsiveButton) {
                return null;
            }

            return responsiveButton || (
                <Button
                    testID={`${testID}-responsive-button`}
                    text="Open"
                    onPress={() => adjustDrawer(false)}
                />
            );
        };

        return (
            <>
                {renderResponsiveButton()}
                <OutsideClickHandler onPress={() => adjustDrawer(true)}>
                    <Portal>
                        <Animated.View
                            testID={`${testID}-hidden-side-nav`}
                            style={[
                                styles.hiddenSideNav,
                                containerStyle,
                                {
                                    top: sideNavTop,
                                    left: slideDrawerAnim,
                                    height:
                                        Dimensions.get('screen').height -
                                        sideNavTop,
                                    minWidth: 200,
                                },
                            ]}
                            onLayout={handleSideNavLayout}
                        >
                            <>{getSideNavContent()}</>
                        </Animated.View>
                    </Portal>
                </OutsideClickHandler>
                <View
                    testID={`${testID}-hidden-side-nav-shadow`}
                    style={[
                        styles.shadow,
                        {
                            display: showResponsiveDrawer ? 'flex' : 'none',
                            left: 0,
                            right: 0,
                            top: containerStyle?.top || 0,
                            height:
                                Dimensions.get('screen').height -
                                parseInt(
                                    containerStyle?.top?.toString() || '0',
                                ),
                        },
                    ]}
                />
            </>
        );
    };

    const sideNav = useMemo(() => {
        if (isMobile || isTablet) {
            return getHiddenSideNavContent();
        } else if (!showResponsiveDrawerOverride) {
            return getSideNavContent();
        }
        return null;
    }, [screenType, containerStyle, items, sideNavContainerWidth]);

    return <>{sideNav}</>;
};

export default SideNavigation;

export const createStyles = (theme: BuilderTheme) => StyleSheet.create({
    container: {
        flexDirection: 'column',
        backgroundColor: theme?.colors?.background ?? 'white',
        padding: theme?.spacing?.padding ?? 16,
        borderRightColor: theme?.colors?.border ?? 'gray',
        borderRightWidth: 1,
        width: '100%',
    },
    contentContainerStyle: {
        height: '100%',
    },
    header: {
        fontSize: theme?.typography?.size?.xl?.fontSize ?? 20,
        fontWeight: theme?.typography?.fontWeight?.bold ?? '700',
        lineHeight: theme?.typography?.size?.xl?.lineHeight ?? 28,
        paddingVertical: theme?.spacing?.padding ?? 8,
        paddingHorizontal: theme?.spacing?.padding ?? 12,
        alignItems: 'center',
    },
    navigationContainerItem: {
        paddingVertical: theme?.spacing?.padding ? theme.spacing.padding / 2 : 4,
    },
    navigationTextItem: {
        fontSize: theme?.typography?.size?.base?.fontSize ?? 16,
        lineHeight: theme?.typography?.size?.base?.lineHeight ?? 24,
        paddingVertical: theme?.spacing?.padding ?? 8,
        paddingHorizontal: theme?.spacing?.padding ?? 12,
        alignItems: 'center',
    },
    subMenuNavigationTextItem: {
        paddingVertical: theme?.spacing?.padding ? theme.spacing.padding / 2 : 6,
        paddingHorizontal: theme?.spacing?.padding ?? 16,
        fontSize: theme?.typography?.size?.sm?.fontSize ?? 14,
        lineHeight: theme?.typography?.size?.sm?.lineHeight ?? 22,
        fontWeight: theme?.typography?.fontWeight?.normal ?? '400',
        borderLeftColor: theme?.colors?.border ?? 'gray',
        borderLeftWidth: theme?.spacing?.borderWidth ?? 1,
    },
    isSelectedSubMenuTextStyle: {
        borderLeftColor: theme?.colors?.primary ?? '#6c00ea',
        borderLeftWidth: theme?.spacing?.borderWidth ?? 2,
    },
    isSelectedTextStyle: {
        backgroundColor: theme?.colors?.secondary ?? '#f4edff',
        borderRadius: theme?.spacing?.borderRadius ?? 5,
    },
    hiddenSideNav: {
        position: 'absolute',
        zIndex: 10,
        bottom: 0,
        minWidth: 200,
    },
    shadow: {
        position: 'absolute',
        zIndex: 9,
        bottom: 0,
        backgroundColor: theme?.colors?.shadow ?? 'black',
        opacity: 0.5,
    },
});
