import moment from 'moment'
import React, { useState } from 'react'
import { Pressable, View, Text, Image, Platform } from 'react-native'
import { Calendar } from 'react-native-calendars'
import { ICalendar } from './interface'
import { formatedDate, periodConstructor } from '../utils'
import { useTheme } from '../../ThemeProvider/ThemeProvider'
import { createStyles } from './styles'
import COLORS from '../../../utils/colors'
import OutsideClickHandler from '../../../molecules/OutsideClickHandler'

const DateRangeCalendar: React.FC<ICalendar> = (props) => {
    const [isCalendarVisible, setCalendarVisible] = useState(false)
    const [fromDate, setFromDate] = useState<string>('')
    const [toDate, setToDate] = useState<string>('')

    const {
        minDate,
        maxDate,
        markingType,
        markedDates,
        renderHeader,
        renderArrow,
        hidePlaceHolder,
        hidePlaceHolder2,
        placeHolder = 'From Date',
        placeHolder2 = 'To Date',
        displayDateFormat = 'DD MMMM',
        calendarAsPopup = true,
        arrowLeft,
        arrowRight,
        placeholderContainer,
        calendarContainer,
        iconContainer,
        iconStyle,
        containerStyle,
        theme: propTheme,
        ...restProps
    } = props
    
    const { theme } = useTheme()
    const styles = createStyles(theme)

    // On day press
    const onDayPress = (dateString: string) => {
        if (markingType === 'period') {
            if (!fromDate) {
                setFromDate(dateString)
                setToDate('')
            } else {
                const isNewDateBigger = Date.parse(dateString) > Date.parse(fromDate)
                if (isNewDateBigger) {
                    setToDate(dateString)
                } else {
                    setToDate(toDate || fromDate)
                    setFromDate(dateString)
                }
            }
        } else {
            setFromDate(dateString)
        }
    }

    const momentNow = moment()
    const today = momentNow.format('YYYY-MM-DD')
    const todayCustomStyles = {
        container: styles.todayContainer,
        text: styles.todayText,
    }
    const todayMarker = { [today]: { customStyles: todayCustomStyles } }
    const selectedCustomStyles = {
        container: styles.selectedContainer,
        text: styles.selectedText,
    }
    const selectedMarker = {
        [fromDate]: {
            customStyles: selectedCustomStyles,
            selected: true,
        },
    }
    const period = periodConstructor(fromDate, toDate, todayMarker)

    const arrowRightUrl = 'https://i.imgur.com/zuh7pXB.png'
    const arrowLeftUrl = 'https://i.imgur.com/5qQ3eEO.png'

    return (
        <>
            <View style={[{ flexDirection: 'row' }, containerStyle]} testID="calendarPlaceholders">
                {!hidePlaceHolder ? (
                    <Pressable
                        onPress={() => setCalendarVisible(!isCalendarVisible)}
                        testID="calendarButton"
                        style={[styles.placeholderContainer, { marginRight: 4 }, placeholderContainer]}
                    >
                        <Text>
                            {fromDate ? formatedDate(fromDate, displayDateFormat) : placeHolder}
                        </Text>
                    </Pressable>
                ) : null}
                {!hidePlaceHolder2 && markingType === 'period' ? (
                    <Pressable
                        testID="button2"
                        onPress={() => setCalendarVisible(!isCalendarVisible)}
                        style={[styles.placeholderContainer, { marginLeft: 4 }, placeholderContainer]}
                    >
                        <Text>
                            {toDate ? formatedDate(toDate, displayDateFormat) : placeHolder2}
                        </Text>
                    </Pressable>
                ) : null}
            </View>

            {isCalendarVisible ? (
                <OutsideClickHandler onPress={() => setCalendarVisible(false)}>
                    <Calendar
                        // ### Generic
                        current={today}
                        minDate={minDate}
                        maxDate={maxDate}
                        markingType={markingType || 'custom'}
                        markedDates={markedDates ? markedDates : markingType === 'period' ? period : selectedMarker}
                        enableSwipeMonths={true}
                        // ### Render
                        renderHeader={renderHeader}
                        renderArrow={(direction) => {
                            return (
                                <View style={[styles.iconContainer, iconContainer]}>
                                    {direction === 'right' ? (
                                        arrowRight || (
                                            <Image source={{ uri: arrowRightUrl }} style={styles.iconStyle} />
                                            )
                                        ) : (
                                            arrowLeft || (
                                                <Image source={{ uri: arrowLeftUrl }} style={styles.iconStyle} />
                                            )
                                        )}
                                    </View>
                                )
                            }}
                            // ### OnPress
                            onDayPress={(day) => {
                                onDayPress(day.dateString)
                            }}
                            // ### Styles
                            style={[
                                styles.calendarContainer,
                                calendarAsPopup && styles.calanderPopUp,
                                calendarContainer,
                                Platform.OS !== 'web' ? { width: '100%' } : {},
                            ]}
                            theme={{
                                calendarBackground: 'transparent',
                                textSectionTitleColor: theme?.colors?.text ?? COLORS.COLOR_GRAY,
                                todayTextColor: theme?.colors?.text ?? COLORS.COLOR_LIGHTBLACK,
                                dayTextColor: theme?.colors?.text ?? COLORS.COLOR_LIGHTBLACK,
                                textDisabledColor: theme?.colors?.disabled ?? COLORS.COLOR_GRAY_PLACEHOLDER,
                                textDayStyle: {
                                    fontSize: theme?.typography?.size?.sm?.fontSize ?? 14,
                                    fontWeight: theme?.typography?.fontWeight?.normal ?? '400',
                                },
                                ...propTheme,
                            }}
                            {...restProps}
                        />
                    </OutsideClickHandler>
                ) : null}
            </>
        )
    }
    
    export default DateRangeCalendar
    
