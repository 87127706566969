import React from 'react'
import { View } from 'react-native'
import { Size } from '../../../src/utils/constants'
import { createStyles } from './styles'
import { useTheme } from '../ThemeProvider/ThemeProvider'

export const CardLoading = ({
    size,
    containerStyle,
    testID,
    topIconPosition,
}) => {
    const { theme } = useTheme()
    const styles = createStyles(theme)

    const isSmallCard = size === Size.Small
    const isMediumCard = size === Size.Medium

    return (
        <View
            testID={testID}
            style={[styles.containerStyle, styles.shadowProp, containerStyle]}
        >
            <View
                style={[
                    styles.topRightIconLoading,
                    {
                        alignSelf:
                            topIconPosition === 'left'
                                ? 'flex-start'
                                : 'flex-end',
                    },
                ]}
            />

            <View style={styles.productImageLoading} />

            {isMediumCard && (
                <View style={styles.productDetailContainer}>
                    <View style={styles.titleLoading} />
                    <View style={styles.descriptionLoading} />
                    <View style={styles.priceLoading} />
                </View>
            )}

            {isSmallCard && (
                <View
                    style={[
                        styles.productDetailContainer,
                        styles.descriptionLoadingSmall,
                    ]}
                >
                    <View style={styles.titleLoading} />
                    <View style={styles.descriptionLoading} />
                </View>
            )}
        </View>
    )
}

