import React from 'react';
import { View, Text, TouchableOpacity, TextInput } from 'react-native';
import createStyles from './styles';
import { DateInputProps } from './interface';
import { useTheme } from '../../ThemeProvider/ThemeProvider';

export const DateInput: React.FC<DateInputProps> = ({
    hideInput,
    dateInputContainerStyles,
    renderInput,
    inputValue,
    view,
    setView,
}) => {
    const { theme } = useTheme();
    const styles = createStyles(theme);
    
    if (hideInput) return null;

    return (
        <View style={[styles.dateInputContainer, dateInputContainerStyles]}>
            {renderInput !== undefined ? (
                renderInput(inputValue)
            ) : (
                <View
                    style={{
                        width: '100%',
                        flexShrink: 1,
                        flexDirection: 'row',
                        alignItems: 'center',
                        borderWidth: theme?.spacing?.borderWidth ?? 1,
                        borderColor: theme?.colors?.border ?? '#E5E7EB',
                        borderRadius: theme?.spacing?.borderRadius ?? 8,
                    }}
                >
                    <TextInput
                        style={[styles.dateInput, { color: theme?.colors?.text ?? '#374151' }]}
                        value={inputValue}
                        editable={false}
                        selectTextOnFocus={false}
                    />
                    <TouchableOpacity
                        style={styles.calendarIcon}
                        onPress={() =>
                            setView(view === 'monthly' ? 'weekly' : 'monthly')
                        }
                    >
                        <Text>📅</Text>
                    </TouchableOpacity>
                </View>
            )}
        </View>
    );
};
