import React from 'react'
import {
    Pressable,
    View,
    ViewStyle,
    TextStyle,
    PressableProps,
    StyleProp,
} from 'react-native'
import { createStyles } from './styles'
import LinearGradientComponent from '../LinearGradient'
import COLORS from '../../utils/colors'
import { Typography } from '../Typography/Typography'
import { useTheme } from '../ThemeProvider/ThemeProvider'

export interface ChipsProps extends PressableProps {
    /** state or theme of the chips */
    state?: 'default' | 'not active' | 'active'
    /** text on the chips */
    text?: string
    /** style of the chips container */
    style?: ViewStyle
    /** style of the text on the chips */
    textStyle?: StyleProp<TextStyle>
    /** color 1 for the gradient in active chips */
    gradientColor1?: string
    /** color 2 for the gradient in active chips */
    gradientColor2?: string
    /** icon url in the chips */
    icon?: React.ReactNode
    /** position of the icon it should be either left or right */
    iconPosition?: 'left' | 'right'
    /** number of text lines in the chips */
    numberOfLines?: number
}

const Chips: React.FC<ChipsProps> = ({
    state = 'default',
    text,
    style,
    textStyle,
    gradientColor1 = 'transparent',
    gradientColor2 = 'transparent',
    icon,
    iconPosition = 'left',
    numberOfLines = 1,
    ...pressableProps
}) => {
    const { theme } = useTheme()
    const styles = createStyles(theme)

    const defaultChips = () => (
        <Pressable
            style={[
                styles.defaultChips,
                {
                    flexDirection:
                        iconPosition == 'left' ? 'row' : 'row-reverse',
                },
                style,
            ]}
            {...pressableProps}
        >
            {icon}
            {text && (
                <Typography
                    numberOfLines={numberOfLines}
                    style={[
                        styles.text,
                        textStyle,
                    ]}
                >
                    {text}
                </Typography>
            )}
        </Pressable>
    )

    const notActiveChips = () => (
        <>
            <Pressable
                style={[
                    styles.notActiveChips,
                    {
                        flexDirection:
                            iconPosition == 'left' ? 'row' : 'row-reverse',
                    },
                    style,
                ]}
                {...pressableProps}
            >
                {icon}
                {text && (
                    <Typography
                        numberOfLines={numberOfLines}
                        style={[
                            styles.text,
                            styles.notActiveText,
                            textStyle,
                        ]}
                    >
                        {text}
                    </Typography>
                )}
            </Pressable>
        </>
    )

    const activeChips = () => (
        <Pressable {...pressableProps}>
            <LinearGradientComponent
                style={{
                    borderRadius: 26,
                    ...style,
                }}
                colors={[gradientColor2, gradientColor1]}
            >
                <View
                    style={[
                        styles.activeChips,
                        {
                            flexDirection:
                                iconPosition == 'left' ? 'row' : 'row-reverse',
                        },
                        style,
                    ]}
                >
                    {icon}
                    {text && (
                        <Typography
                            numberOfLines={numberOfLines}
                            style={[
                                styles.text,
                                styles.activeText,
                                textStyle,
                            ]}
                        >
                            {text}
                        </Typography>
                    )}
                </View>
            </LinearGradientComponent>
        </Pressable>
    )

    return state === 'active'
        ? activeChips()
        : state === 'not active'
          ? notActiveChips()
          : defaultChips()
}

export default Chips
