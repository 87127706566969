import { StyleSheet } from 'react-native'
import { BuilderTheme } from '../ThemeProvider/themes'

const hs = (value: number) => value
const ws = (value: number) => value
const ms = (value: number) => value

export const createStyles = (theme: BuilderTheme) =>
    StyleSheet.create({
        container: {
            display: 'flex',
            maxWidth: '100%',
            alignSelf: 'flex-start',
            flexDirection: 'row',
            height: hs(54),
            backgroundColor: theme?.colors?.background ?? 'white',
            borderRadius: ms(theme?.spacing?.borderRadius) ?? ms(6),
            zIndex: 5,
            alignItems: 'center',
            overflow: 'hidden',
            shadowOffset: { width: 1, height: 2 },
            shadowColor: theme?.colors?.shadow ?? '#171717',
            shadowOpacity: 0.2,
            shadowRadius: 10,
        },
        innerContainer: {
            alignSelf: 'flex-start',
            flexDirection: 'row',
            height: hs(54),
            backgroundColor: theme?.colors?.background ?? 'white',
            borderRadius: ms(theme?.spacing?.borderRadius) ?? ms(6),
            zIndex: 5,
            maxWidth: '100%',
            alignItems: 'center',
            overflow: 'hidden',
        },
        leftContainer: {
            height: hs(42),
            width: ws(42),
            borderRadius: ms(theme?.spacing?.borderRadius) ?? ms(6),
            marginHorizontal: theme?.spacing?.margin ?? ws(6),
            backgroundColor: theme?.colors?.secondary ?? '#F0E5FF',
            alignItems: 'center',
            justifyContent: 'center',
            overflow: 'hidden',
        },
        centerContainer: {
            height: '100%',
            marginHorizontal: theme?.spacing?.margin ?? ws(12),
            marginLeft: theme?.spacing?.margin ?? 6,
            paddingVertical: theme?.spacing?.padding ?? hs(4),
            flexWrap: 'wrap',
            flexShrink: 1,
            justifyContent: 'center',
        },
        rightContainer: {
            marginHorizontal: 4,
        },
        title: {
            fontSize: theme?.typography?.size?.sm?.fontSize ?? ms(12),
            fontWeight: theme?.typography?.fontWeight?.normal ?? '400',
            color: theme?.colors?.text ?? '#000',
            width: '100%',
            marginBottom: 3,
        },
        subTitle: {
            fontSize: theme?.typography?.size?.xs?.fontSize ?? ms(12),
            fontWeight: theme?.typography?.fontWeight?.normal ?? '400',
            color: theme?.colors?.text ?? '#64748B',
        },
        imageStyle: {
            height: '100%',
            width: '100%',
            resizeMode: 'stretch',
            overflow: 'hidden',
        },
        rightIconStyle: {
            height: '100%',
            width: '100%',
            resizeMode: 'stretch',
            overflow: 'hidden',
        },
        iconStyle: {
            height: hs(18),
            width: ws(18),
            overflow: 'hidden',
            resizeMode: 'stretch',
        },
        progress: {
            position: 'absolute',
            top: 0,
            left: 0,
            height: 2,
            width: '100%',
            zIndex: 1,
        },
    })
