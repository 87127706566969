import { StyleSheet } from 'react-native'
import COLORS from '../../../utils/colors'
import { BuilderTheme } from '../../ThemeProvider/themes'
const hs = (value: number) => value
const ws = (value: number) => value
const ms = (value: number) => value

export const createStyles = (theme?: BuilderTheme) => {
    return StyleSheet.create({
        calanderPopUp: {
            position: 'absolute',
            left: 0,
            top: 0,
        },
        calendarContainer: {
            width: ws(364),
            backgroundColor: theme?.colors?.background || 'white',
            paddingVertical: theme?.spacing?.padding ?? hs(16),
            marginVertical: theme?.spacing?.margin ?? hs(4),
            borderRadius: theme?.spacing?.borderRadius ?? ms(4),
            shadowOffset: { width: 2, height: 4 },
            shadowColor: theme?.colors?.shadow ?? COLORS.COLOR_SHADOW,
            shadowOpacity: 0.2,
            shadowRadius: 10,
            paddingHorizontal: theme?.spacing?.padding ?? 15,
        },
        todayContainer: {},
        todayText: {
            fontWeight: 'bold',
        },
        selectedContainer: {
            backgroundColor: theme?.colors?.secondaryBackground || '#F0E5FF',
        },
        selectedText: {
            color: theme?.colors?.primary || '#6200EA',
        },
        placeholderContainer: {
            height: 44,
            flex: 1,
            backgroundColor: theme?.colors?.background || 'white',
            justifyContent: 'center',
            paddingHorizontal: theme?.spacing?.padding ?? ws(16),
            marginBottom: theme?.spacing?.margin ?? hs(2),
            borderWidth: theme?.spacing?.borderWidth ?? ms(1),
            borderRadius: theme?.spacing?.borderRadius ?? ms(8),
            borderColor: theme?.colors?.primary || '#6200EA',
        },
        iconStyle: {
            height: '100%',
            width: '100%',
            resizeMode: 'stretch',
        },
        iconContainer: {
            height: 20,
            width: 20,
        },
    })
}
