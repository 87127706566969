import React, { FC, useMemo } from 'react'
import { ImageBackground, Text, View, StyleSheet } from 'react-native'
import LinearGradientComponent from '../LinearGradient'
import { BigCardProps } from '../Card/interface'
import Button from '../Button/Button'
import { useTheme } from '../ThemeProvider/ThemeProvider'
import { BuilderTheme } from '../ThemeProvider/themes'

export interface BigProductCardProps extends BigCardProps {}

export const BigProductCard: FC<BigProductCardProps> = ({
    containerStyle,
    buttonProps,
    gradientColors = [],
    title,
    description,
    buttonText,
    backgroundImageUrl,
    backgroundImage,
    titleTextStyle,
    descriptionTextStyle,
    testID,
}) => {
    const { theme } = useTheme()
    const styles = createStyles(theme)

    const button = useMemo(() => {
        if (!buttonText) return null

        return typeof buttonText === 'string' ? (
            <Button
                {...buttonProps}
                style={[styles.buttonStyle, buttonProps?.style || []]}
                text={buttonText}
            />
        ) : (
            buttonText
        )
    }, [buttonText, buttonProps, theme])

    return (
        <View style={[styles.containerStyle, containerStyle]} testID={testID}>
            <ImageBackground
                resizeMode="cover"
                source={backgroundImage || { uri: backgroundImageUrl }}
                style={styles.imageBackgroundStyle}
            >
                <LinearGradientComponent
                    colors={gradientColors}
                    style={styles.gradientOverlay}
                >
                    <View style={styles.contentContainerStyle}>
                        <Text style={[styles.titleStyle, titleTextStyle]}>
                            {title}
                        </Text>
                        <Text
                            style={[styles.descriptionStyle, descriptionTextStyle]}
                        >
                            {description}
                        </Text>
                        {button}
                    </View>
                </LinearGradientComponent>
            </ImageBackground>
        </View>
    )
}

const createStyles = (theme: BuilderTheme) =>
    StyleSheet.create({
        containerStyle: {
            borderRadius: theme?.spacing?.borderRadius ?? 8,
            overflow: 'hidden',
        },
        imageBackgroundStyle: {
            flex: 1,
            height: '100%',
        },
        descriptionStyle: {
            fontSize: theme?.typography?.size?.['3xl'].fontSize ?? 30,
            color: theme?.colors?.text ?? 'white',
            fontWeight: theme?.typography?.fontWeight?.bold ?? 'bold',
            paddingTop: theme?.spacing?.padding ?? 4,
            flexShrink: 1,
        },
        titleStyle: {
            color: theme?.colors?.text ?? 'white',
            fontSize: theme?.typography?.size?.sm?.fontSize ?? 14,
            fontWeight: theme?.typography?.fontWeight?.bold ?? 'bold',
        },
        contentContainerStyle: {
            justifyContent: 'flex-end',
            flex: 1,
            padding: 16,
        },
        buttonStyle: {
            backgroundColor: theme?.colors?.primary ?? 'white',
            alignSelf: 'flex-start',
            marginTop: theme?.spacing?.margin ?? 24,
            paddingVertical: theme?.spacing?.padding ?? 10,
            paddingHorizontal: theme?.spacing?.padding * 2 || 16,
            borderRadius: theme?.spacing?.borderRadius ?? 8,
        },
        gradientOverlay: {
            height: '100%',
        },
    })
