import React, { FC } from 'react';
import {
    View,
    FlatList,
    Pressable,
    PressableProps,
    ViewStyle,
    TextStyle,
    StyleProp,
} from 'react-native';
import createStyles from './styles';
import { useTheme } from '../ThemeProvider/ThemeProvider';

import { Typography } from '../Typography/Typography';

export interface ListItemProps extends Omit<PressableProps, 'onPress'> {
    listData: Array<ListDataItem>;
    containerStyle?: StyleProp<ViewStyle>;
    textViewStyle?: TextStyle;
    titleTextStyle?: TextStyle;
    detailTextStyle?: TextStyle;
    listItemStyle?: StyleProp<ViewStyle>;
    /** OnPress will be called passing the item that triggers the event */
    onPress?: (item: ListDataItem) => void;
}

export type ListDataItem = {
    title: string;
    iconLeft?: React.ReactNode;
    iconRight?: React.ReactNode;
    children?: React.ReactNode;
    details?: string;
};

/**
 * The props extend this interface `extends Omit<PressableProps, 'onPress'>`.
 *
 */
const ListItem: FC<ListItemProps> = ({
    listData,
    containerStyle,
    textViewStyle,
    titleTextStyle,
    detailTextStyle,
    listItemStyle,
    onPress,
    ...otherPressableProps
}) => {
    const { theme } = useTheme();
    const styles = createStyles(theme);

    const ItemDivider = () => <View style={styles.itemSeparator} />;

    const renderItem = ({ item }: { item: ListDataItem }) => (
        <Pressable
            style={[styles.container, listItemStyle]}
            onPress={() => onPress(item)}
            {...otherPressableProps}
        >
            <View style={styles.column}>
                <View style={styles.row}>
                    {item?.iconLeft}
                    <View
                        style={[
                            styles.textView,
                            styles.marginHorizontal,
                            textViewStyle,
                        ]}
                    >
                        <Typography style={[styles.text, titleTextStyle]}>
                            {item?.title}
                        </Typography>
                        <Typography style={[styles.subText, detailTextStyle]}>
                            {item?.details}
                        </Typography>
                    </View>
                    {item?.iconRight && (
                        <View style={styles.iconRightContainer}>
                            {item.iconRight}
                        </View>
                    )}
                </View>
                {item?.children && (
                    <View style={styles.childrenContainer}>
                        {item.children}
                    </View>
                )}
            </View>
        </Pressable>
    );

    return (
        <FlatList
            style={containerStyle}
            data={listData}
            keyExtractor={(item, index) => index.toString()}
            renderItem={renderItem}
            ItemSeparatorComponent={ItemDivider}
        />
    );
};
    
export default ListItem;

