import { StyleSheet } from 'react-native'
import { moderateScale } from '../../utils/screenRatio'
import { BuilderTheme } from '../ThemeProvider/themes'

export const createStyles = (theme: BuilderTheme) =>
    StyleSheet.create({
        container: {
            width: 'min-content',
            overflow: 'visible',
        },
        menuProviderStyles: {
            overflow: 'visible',
        },
        optionText: {
            fontSize: moderateScale(7),
            color: theme?.colors?.text || '#3A3A3C', // default color
            alignItems: 'center',
            fontWeight: theme?.typography?.fontWeight?.normal || '400',
            marginLeft: 12,
        },
        icon: {
            tintColor: theme?.colors?.text || '#3A3A3C', // default color
            height: 15,
            width: 12,
        },
        highlightedIconView: {
            borderRadius: theme?.spacing?.borderRadius || 25,
            width: 40,
            height: 40,
            justifyContent: 'center',
            alignItems: 'center',
        },
        highlightedIcon: {
            height: 15,
            width: 12,
        },
        optionContainer: {
            flexDirection: 'row',
            alignContent: 'center',
            alignItems: 'center',
            justifyContent: 'space-between',
            margin: 3,
            borderRadius: theme?.spacing?.borderRadius || 8,
            width: '100%',
        },
        menuOptionStyle: {
            borderRadius: theme?.spacing?.borderRadius || 8,
        },
        rightIcon: {
            tintColor: theme?.colors?.text || '#3A3A3C', // default color
            height: 12,
            width: 16,
        },
        leftIcon: {
            tintColor: theme?.colors?.text || '#3A3A3C', // default color
            height: 12,
            width: 16,
        },
        submenuContainer: {
            position: 'absolute',
            left: '100%',
            top: 0,
            backgroundColor: theme?.colors?.background || '#FFFFFF', // default color
            borderRadius: theme?.spacing?.borderRadius || 5,
            borderWidth: theme?.spacing?.borderWidth || 1,
            borderColor: theme?.colors?.border || '#E0E0E0', // default color
            shadowOffset: { width: 0, height: 2 },
            shadowColor: theme?.colors?.shadow || '#000000', // default color
            shadowOpacity: 0.2,
            shadowRadius: 2,
            elevation: 4,
            minWidth: 200,
            marginLeft: 12,
        },
        imageView: {
            flexDirection: 'row',
            alignItems: 'center',
        },
        itemContainer: {
            position: 'absolute',
        },

        optionsContainer: {
            minWidth: 200,
            backgroundColor: theme?.colors?.background || '#FFFFFF', // default color
            paddingVertical: theme?.spacing?.padding || 7,
            paddingHorizontal: theme?.spacing?.padding || 10,
            borderRadius: theme?.spacing?.borderRadius || 5,
            borderWidth: theme?.spacing?.borderWidth || 1,
            borderColor: theme?.colors?.border || '#E0E0E0', // default color
            shadowOffset: { width: 0, height: 2 },
            shadowColor: theme?.colors?.shadow || '#000000', // default color
            shadowOpacity: 0.2,
            shadowRadius: 2,
            elevation: 4,
            overflow: 'visible',
        },
    })

export const createNestedOptionsStyles = (theme: BuilderTheme) =>
    StyleSheet.create({
        optionsContainer: {
            backgroundColor: theme?.colors?.background || '#FFFFFF', // default color
            paddingVertical: theme?.spacing?.padding || 7,
            paddingHorizontal: theme?.spacing?.padding || 12,
            marginHorizontal: 11,
            borderRadius: theme?.spacing?.borderRadius || 5,
        },
        optionWrapper: {
            paddingVertical: theme?.spacing?.padding || 8,
            paddingHorizontal: theme?.spacing?.padding || 16,
        },
    })
