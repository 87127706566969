import React from 'react'

import { Switch, SwitchProps } from '@rneui/themed'
import { useTheme } from '../ThemeProvider/ThemeProvider'

export interface ToggleProps extends SwitchProps {}

export function Toggle(props: ToggleProps) {
    const { value, ...rest } = props
    const { theme } = useTheme();

    const color = value ? theme?.colors?.primary || '#6200EA' : theme?.colors?.disabled || '#E2E8F0'
    return (
        <Switch color={color} value={value} {...rest} />
    )
}
