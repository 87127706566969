import { StyleSheet } from 'react-native'
import { BuilderTheme } from '../ThemeProvider/themes'
import COLORS from '../../utils/colors'

export const createStyles = (theme?: BuilderTheme) =>
    StyleSheet.create({
        containerStyle: {
            backgroundColor: theme?.colors?.background ?? COLORS.COLOR_WHITE,
            borderRadius: theme?.spacing?.borderRadius ?? 8,
            padding: theme?.spacing?.padding ?? 16,
        },
        productImage: {
            paddingHorizontal: theme?.spacing?.padding ?? 10,
        },
        productDetailContainer: {
            paddingTop: theme?.spacing?.padding ?? 8,
        },
        titleTextStyle: {
            fontSize: theme?.typography?.size?.sm?.fontSize ?? 14,
            fontWeight: theme?.typography?.fontWeight.bold ?? '700',
            lineHeight: theme?.typography?.size?.sm?.lineHeight ?? 22,
            color: theme?.colors?.text ?? COLORS.COLOR_LIGHTBLACK,
        },
        priceTextStyle: {
            paddingTop: theme?.spacing?.padding ?? 8,
            fontSize: theme?.typography?.size?.sm?.fontSize ?? 14,
            fontWeight: theme?.typography?.fontWeight?.normal ?? '400',
            lineHeight: theme?.typography?.size?.sm?.lineHeight ?? 22,
            color: theme?.colors?.text ?? COLORS.COLOR_LIGHTBLACK,
        },
        descriptionTextStyle: {
            fontSize: theme?.typography?.size?.sm?.fontSize ?? 14,
            fontWeight: theme?.typography?.fontWeight?.normal ?? '400',
            lineHeight: theme?.typography?.size?.sm?.lineHeight ?? 22,
            color: theme?.colors?.text ?? COLORS.COLOR_GRAY,
        },
        shadowProp: {
            shadowOffset: { width: 2, height: 4 },
            shadowColor: theme?.colors?.shadow ?? COLORS.COLOR_SHADOW,
            shadowOpacity: 0.2,
            shadowRadius: 10,
        },
        topRightIconLoading: {
            alignSelf: 'flex-end',
            width: 40,
            height: 40,
            backgroundColor: theme?.colors?.loading ?? COLORS.COLOR_LIGHTPURPLE,
            borderRadius: theme?.spacing?.borderRadius ?? 8,
            position: 'absolute',
            top: 10,
            zIndex: 1,
        },
        productImageLoading: {
            width: '100%',
            height: 155,
            backgroundColor: theme?.colors?.loading ?? COLORS.COLOR_BTNGRAY,
            borderRadius: theme?.spacing?.borderRadius ?? 8,
            marginTop: theme?.spacing?.margin ?? 10,
        },
        titleLoading: {
            width: '70%',
            padding: theme?.spacing?.padding ?? 10,
            backgroundColor: theme?.colors?.loading ?? COLORS.COLOR_LIGHTGRAY,
            borderRadius: theme?.spacing?.borderRadius ?? 8,
            marginTop: theme?.spacing?.margin ?? 10,
        },
        descriptionLoading: {
            width: '100%',
            padding: theme?.spacing?.padding ?? 10,
            backgroundColor: theme?.colors?.loading ?? COLORS.COLOR_BTNGRAY,
            borderRadius: theme?.spacing?.borderRadius ?? 8,
            marginTop: theme?.spacing?.margin ?? 10,
        },
        descriptionLoadingSmall: {
            maxWidth: 180,
        },
        priceLoading: {
            width: '40%',
            padding: theme?.spacing?.padding ?? 10,
            backgroundColor: theme?.colors?.loading ?? COLORS.COLOR_LIGHTGRAY,
            borderRadius: theme?.spacing?.borderRadius ?? 8,
            marginTop: theme?.spacing?.margin ?? 15,
        },
        containerStyleMobile: {
            maxWidth: 160,
            height: 'fit',
            backgroundColor: theme?.colors?.background ?? COLORS.COLOR_WHITE,
            borderRadius: theme?.spacing?.borderRadius ?? 8,
            padding: theme?.spacing?.padding ?? 16,
        },
        productImageMobile: {},
        productDetailContainerMobile: {
            paddingTop: theme?.spacing?.padding ?? 8,
        },
        gradientOverlay: {
            height: '100%',
        },
        actionButtonContainerStyle: {
            marginTop: theme?.spacing?.margin ?? 10,
        },
    })
