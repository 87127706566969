import React from 'react'
import { Pressable, View, Text } from 'react-native'
import { useStyles } from './style'
import { FormHeaderProps } from './interface'

const FormHeader = (props: FormHeaderProps) => {
    const {
        titleText,
        iconRight,
        detailsText,
        iconPress,
        titleTextStyle,
        detailsTextStyle,
        containerStyle,
        testID,
    } = props
    
    const styles = useStyles()

    return (
        <View style={[styles.mainContainer, containerStyle]} testID={testID}>
            <View style={styles.subView}>
                <Text style={[styles.titleTextStyle, titleTextStyle]}>
                    {titleText}
                </Text>
                <Pressable onPress={iconPress}>{iconRight}</Pressable>
            </View>
            {detailsText && (
                <View style={styles.detailViewStyle}>
                    <Text style={[styles.detailsTextStyle, detailsTextStyle]}>
                        {detailsText}
                    </Text>
                </View>
            )}
        </View>
    )
}

export default FormHeader
