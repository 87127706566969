import React, { useState, useEffect, useCallback } from 'react';
import {
    View,
    Image,
    Pressable,
    FlatList,
    StyleProp,
    ImageStyle,
    ViewStyle,
    StyleSheet,
} from 'react-native';

import createStyles from './styles';
import STRINGS from '../../utils/strings';
import { Typography, TypographyProps } from '../Typography/Typography';
import { useTheme } from '../ThemeProvider/ThemeProvider';

export interface PaginationProps {
    siblingCount?: number;
    boundaryCount?: number;
    pageCount?: number;
    initialPage?: number;
    totalItems?: number;
    onPageChange?: (page: number) => void;
    containerStyle?: StyleProp<ViewStyle>;
    subContainerStyle?: StyleProp<ViewStyle>;
    paginationItemStyle?: StyleProp<ViewStyle>;
    textProps?: Omit<TypographyProps, 'children'>;
    activeTextProps?: Omit<TypographyProps, 'children'>;
    ellipsisProps?: Omit<TypographyProps, 'children'> & { children?: string };
    iconStyle?: StyleProp<ImageStyle>;
    iconLeft?: React.ReactNode;
    iconRight?: React.ReactNode;
    showIcon?: boolean;
    showDetailedPageResults?: boolean;
    totalItemProps?: Omit<TypographyProps, 'children'>;
    testID?: string;
    customEllipsis?: React.ReactNode;
}

type PageNumber = number | '...';

export const Pagination: React.FC<PaginationProps> = (props) => {
    const {
        siblingCount = 0,
        boundaryCount,
        pageCount = 1,
        initialPage = 1,
        totalItems,
        onPageChange,
        containerStyle,
        subContainerStyle,
        paginationItemStyle,
        textProps = {},
        activeTextProps = {},
        ellipsisProps = {},
        iconLeft,
        iconRight,
        showIcon,
        showDetailedPageResults,
        totalItemProps = {},
        testID,
        customEllipsis,
        iconStyle,
    } = props;

    const [selectedPage, setSelectedPage] = useState<number>(initialPage);
    const [pageNumbers, setPageNumbers] = useState<PageNumber[]>([]);

    const { theme } = useTheme();
    const styles = createStyles(theme);

    const getPageNumbers = useCallback((): PageNumber[] => {
        const boundaryCountValue = boundaryCount || pageCount;
        const pageNumbersArray: PageNumber[] = [];

        const boundaryStartEnd = Math.max(1, boundaryCountValue);
        const siblingStartStart = Math.max(1, selectedPage - siblingCount);
        const siblingEndEnd = Math.min(pageCount, selectedPage + siblingCount);
        const boundaryEndStart = Math.max(1, pageCount - boundaryCountValue + 1);

        const ellipsis = '...';

        for (let i = 1; i <= pageCount; i++) {
            if (i < selectedPage) {
                if (i <= boundaryStartEnd) {
                    pageNumbersArray.push(i);
                } else if (i === siblingStartStart - 1) {
                    pageNumbersArray.push(ellipsis);
                } else if (i >= siblingStartStart && i <= siblingStartStart + siblingCount) {
                    pageNumbersArray.push(i);
                }
            } else if (i > selectedPage) {
                if (i <= siblingEndEnd) {
                    pageNumbersArray.push(i);
                } else if (i === boundaryEndStart - 1) {
                    if (pageNumbersArray[i] !== ellipsis) pageNumbersArray.push(ellipsis);
                } else if (i >= boundaryEndStart) {
                    pageNumbersArray.push(i);
                }
            } else {
                pageNumbersArray.push(i);
            }
        }

        return pageNumbersArray;
    }, [pageCount, boundaryCount, selectedPage, siblingCount]);

    useEffect(() => {
        setPageNumbers(getPageNumbers());
    }, [getPageNumbers]);


    useEffect(() => {
        setSelectedPage(initialPage);
    }, [initialPage]);

    useEffect(() => {
        if (onPageChange) {
            onPageChange(selectedPage);
        }
    }, [selectedPage, onPageChange]);

    const handlePageChange = (page: number) => {
        setSelectedPage(page);
    };

    const renderPageButton = useCallback(
        ({ item }: { item: PageNumber }) => {
            const isActive = selectedPage === item;
            const isEllipsis = item === '...';

            let style;

            if (isActive) {
                style = StyleSheet.flatten([styles.activePageText, activeTextProps?.style]);
            } else if (isEllipsis) {
                style = StyleSheet.flatten([styles.ellipsisText, ellipsisProps?.style]);
            } else {
                style = StyleSheet.flatten([styles.pageText, textProps?.style]);
            }

            const _item = ellipsisProps?.children 
                ? item.toString().replace('...', ellipsisProps.children) 
                : item;

            return (
                <Pressable
                    testID={STRINGS.PAGE_BTN_TESTID}
                    style={[styles.pageButton, paginationItemStyle]}
                    onPress={() => !isEllipsis && handlePageChange(Number(item))}
                    disabled={isEllipsis}
                >
                    {isEllipsis && customEllipsis ? (
                        customEllipsis
                    ) : (
                        <Typography style={style}>{_item}</Typography>
                    )}
                </Pressable>
            );
        },
        [selectedPage, activeTextProps?.style, ellipsisProps?.style, textProps?.style, 
         paginationItemStyle, ellipsisProps?.children, customEllipsis, styles]
    );

    const goToPrevPage = () => {
        setSelectedPage((prevPage) => Math.max(prevPage - 1, 1));
    };

    const goToNextPage = () => {
        setSelectedPage((prevPage) => Math.min(prevPage + 1, pageCount));
    };

    const getPageRangeText = (): string => {
        if (!totalItems || !pageCount) return `0 - 0 of 0 results`;

        const itemCountPerPage = Math.floor(totalItems / pageCount);
        const itemCountOnLastPage = totalItems % pageCount || itemCountPerPage;

        if (!itemCountPerPage) return `0 - 0 of ${totalItems} results`;

        const startItemCount = (selectedPage - 1) * itemCountPerPage + 1;
        const endItemCount =
            selectedPage === pageCount
                ? startItemCount + itemCountOnLastPage - 1
                : startItemCount + itemCountPerPage - 1;

        return `${startItemCount} - ${endItemCount} of ${totalItems} results`;
    };

    return (
        <View style={[styles.container, containerStyle]} testID={testID}>
            <View style={[styles.subContainer, subContainerStyle]}>
                {showIcon && (
                    <Pressable
                        testID={STRINGS.LEFT_BTN_TESTID}
                        onPress={goToPrevPage}
                        disabled={selectedPage === 1}
                    >
                        {iconLeft || (
                            <Image
                                resizeMode={'contain'}
                                style={[styles.icon, iconStyle]}
                                source={{
                                    uri: 'https://i.imgur.com/wS6e62V.png',
                                }}
                            />
                        )}
                    </Pressable>
                )}
                <View>
                    <FlatList
                        data={pageNumbers}
                        renderItem={renderPageButton}
                        keyExtractor={(item, idx) => idx.toString()}
                        horizontal
                        showsHorizontalScrollIndicator={false}
                    />
                </View>
                {showIcon && (
                    <Pressable
                        testID={STRINGS.RIGHT_BTN_TESTID}
                        onPress={goToNextPage}
                        disabled={selectedPage === pageCount}
                    >
                        {iconRight || (
                            <Image
                                resizeMode={'contain'}
                                style={[styles.icon, iconStyle]}
                                source={{
                                    uri: 'https://i.imgur.com/cK6JBiC.png',
                                }}
                            />
                        )}
                    </Pressable>
                )}
            </View>
            {showDetailedPageResults && totalItems && (
                <View style={styles.paginationDataContainer}>
                    <Typography
                        style={StyleSheet.flatten([styles.totalTextView, totalItemProps?.style])}
                        {...totalItemProps}
                    >
                        {getPageRangeText()}
                    </Typography>
                </View>
            )}
        </View>
    );
};


