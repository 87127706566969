import React, { useState, useCallback } from 'react';
import {
    View,
    Pressable,
    Text,
    Image,
    ViewStyle,
    TextStyle,
    StyleProp,
    PressableProps,
    Platform,
} from 'react-native';
import { useTheme } from '../ThemeProvider/ThemeProvider';
import { createBreadcrumbsStyles } from './styles';
import STRINGS from '../../utils/strings';
import COLORS from '../../utils/colors';

interface BreadcrumbItem {
    id: string;
    name: string;
}

export interface PressableItemsProps extends Omit<PressableProps, 'onPress'> {
    onPress: (index: number) => any;
}

export interface BreadcrumbsProps {
    /** Data for Breadcrumbs */
    testID?: string;
    pageData: BreadcrumbItem[];
    icon?: React.ReactNode;
    textStyle?: StyleProp<TextStyle>;
    activeTextStyle?: StyleProp<TextStyle>;
    hoverViewStyle?: StyleProp<ViewStyle>;
    /** Container content pass to the FlatList */
    containerStyle?: StyleProp<ViewStyle>;
    activePageIndex?: number;
    pressableProps?: PressableItemsProps;
}

const Breadcrumbs: React.FC<BreadcrumbsProps> = ({
    testID,
    pageData,
    icon,
    textStyle,
    activeTextStyle,
    hoverViewStyle,
    containerStyle,
    activePageIndex,
    pressableProps,
}) => {
    const { theme } = useTheme();
    const styles = createBreadcrumbsStyles(theme);

    const [selectedItem, setSelectedItem] = useState<number | null>(activePageIndex || null);
    const [hoveredIndex, setHoveredIndex] = useState<number | null>(null);

    const onIndexChangeVal = useCallback(
        (index: number): void => {
            setSelectedItem(index);
            pressableProps?.onPress(index);
        },
        [pressableProps],
    );

    const onIndexHoverVal = useCallback((index: number | null): void => {
        setHoveredIndex(index);
    }, []);

    const renderIcon = useCallback(
        (index: number): React.ReactNode => {
            if (index > 0) {
                return icon ? (
                    icon
                ) : (
                    <Image
                        resizeMode="contain"
                        style={styles.icon}
                        source={{
                            uri: 'https://i.imgur.com/JuKMaKC.png',
                        }}
                    />
                );
            }
            return null;
        },
        [icon, styles.icon],
    );

    const renderText = useCallback(
        (item: BreadcrumbItem, isSelected: boolean): React.ReactNode => (
            <Text
                style={[
                    isSelected ? styles.boldText : styles.breadcrumbText,
                    isSelected ? activeTextStyle : textStyle,
                ]}
            >
                {item.name}
            </Text>
        ),
        [activeTextStyle, textStyle, styles],
    );

    const renderHoverView = useCallback(
        (isHovered: boolean, item: BreadcrumbItem, isSelected: boolean): React.ReactNode => {
            const hoveredColor = theme?.colors?.hover ?? COLORS.COLOR_BTNGRAY
            const backgroundColor = isHovered ? hoveredColor : 'transparent'
            return (
                <View
                    style={[
                        styles.hoverView,
                        { backgroundColor },
                        isHovered ? hoverViewStyle : {},
                    ]}
                >
                    {renderText(item, isSelected)}
                </View>
            )
        },
        [hoverViewStyle, renderText, styles, theme?.colors?.hover],
    );

    const renderItem = useCallback(
        ({ item, index }: { item: BreadcrumbItem; index: number }): React.ReactElement => {
            const isSelected = selectedItem === index;
            const isHovered = hoveredIndex === index;

            const pressablePropsUpdated = {
                ...pressableProps,
                ...Platform.select({
                    web: {
                        onMouseEnter: () => onIndexHoverVal(index),
                        onMouseLeave: () => onIndexHoverVal(null),
                    },
                }),
            };

            return (
                <View
                    testID={`${testID}-${index}`}
                    style={styles.item}
                    key={index.toString()}
                >
                    {renderIcon(index)}
                    <Pressable
                        testID={STRINGS.BTN_TESTID}
                        {...pressablePropsUpdated}
                        onPress={() => onIndexChangeVal(index)}
                    >
                        {renderHoverView(isHovered, item, isSelected)}
                    </Pressable>
                </View>
            );
        },
        [
            selectedItem,
            hoveredIndex,
            pressableProps,
            onIndexHoverVal,
            renderIcon,
            renderHoverView,
            testID,
            onIndexChangeVal,
        ],
    );

    return (
        <View style={[styles.contentContainer, containerStyle]} testID={testID}>
            {pageData.map((item, index) => renderItem({ item, index }))}
        </View>
    )
}

export default Breadcrumbs;

