import React, { Component } from 'react';
import { View, Text } from 'react-native';
import createStyles from './styles';
import { useTheme } from '../ThemeProvider/ThemeProvider';
import STRINGS from '../../utils/strings';

export interface HeaderProps {
    headerText?: string;
    headerIcon?: React.ReactNode;
    headerViewStyle?: object;
    headerTextStyle?: object;
    leftIcon?: React.ReactNode;
    rightIcon?: React.ReactNode;
    buttonText?: string;
    containerStyle?: object;
    btnTextStyle?: object;
    leftIconContainer?: object;
    rightIconContainer?: object;
    headerIconView?: object;
    testID?: string;
}

const Header = (props: HeaderProps) => {
    const { theme } = useTheme();
    const styles = createStyles(theme);

    const {
        headerText,
        headerIcon,
        headerTextStyle,
        leftIcon,
        rightIcon,
        buttonText,
        containerStyle,
        btnTextStyle,
        headerViewStyle,
        leftIconContainer,
        rightIconContainer,
        headerIconView,
        testID,
    } = props;

    return (
        <View style={[styles.mainView, styles.shadowProp, containerStyle]} testID={testID}>
            <View style={[styles.leftIconView, leftIconContainer]}>{leftIcon}</View>
            <View style={[styles.headerCenterView, headerViewStyle]}>
                {headerIcon ? (
                    <View style={[styles.headerIconView, headerIconView]}>{headerIcon}</View>
                ) : headerText ? (
                    <Text style={[styles.headerText, headerTextStyle]}>
                        {headerText.slice(0, 30)}
                    </Text>
                ) : null}
            </View>
            <View style={[styles.rightIconView, rightIconContainer]}>
                {rightIcon ? (
                    rightIcon
                ) : buttonText ? (
                    <View testID={STRINGS.HEADER_BUTTON}>
                        <Text style={[styles.btnTextStyle, btnTextStyle]}>
                            {buttonText}
                        </Text>
                    </View>
                ) : null}
            </View>
        </View>
    );
};

export default Header;

