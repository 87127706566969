import React, { useState, useEffect } from 'react'
import {
    FlatList,
    ViewStyle,
    TextStyle,
    PressableProps,
    StyleProp,
} from 'react-native'
import { useTheme } from '../ThemeProvider/ThemeProvider'
import createStyles from './styles'
import SegmentedCtrlItem, { SegmentedCtrlItemData } from './SegmentedCtrlItem'

import CONSTANTS from '../../utils/constants'

export interface SegmentedControlProps extends Omit<PressableProps, 'onPress'> {
    size?: 'small' | 'medium' | 'big'
    textStyle?: StyleProp<TextStyle>
    /** data to be displayed on the flatlist */
    dataSet?: Array<SegmentedCtrlItemData>
    /** style for the flatlist container */
    containerStyle?: StyleProp<ViewStyle>
    /** style for the segmented control item container */
    itemStyle?: ViewStyle
    /** selected background color for the selected segmented control item */
    itemSelectdBgColor?: string
    /** selected text color for the selected segmented control item */
    itemSelectdTxtColor?: string
    /** the id of the initial active item */
    initialSelectedId?: string
    onPressItem: (item: SegmentedCtrlItemData) => void
}

export const getSize = (size: 'small' | 'medium' | 'big') => {
    switch (size) {
        case CONSTANTS.SIZE_SMALL:
            return 32
        case CONSTANTS.SIZE_BIG:
            return 56
        case CONSTANTS.SIZE_MEDIUM:
        default:
            return 44
    }
}

const SegmentedControl: React.FC<SegmentedControlProps> = ({
    size = 'medium',
    textStyle,
    dataSet,
    containerStyle,
    itemStyle,
    itemSelectdBgColor = '',
    itemSelectdTxtColor = '',
    initialSelectedId,
    onPressItem,
    testID,
    ...otherPressableProps
}) => {
    const { theme } = useTheme()
    const styles = createStyles(theme) // Provide default theme if undefined

    const [selectedId, setSelectedId] = useState<string | undefined>(
        initialSelectedId,
    )

    useEffect(() => {
        setSelectedId(initialSelectedId)
    }, [initialSelectedId])

    const handlePressItem = (item: SegmentedCtrlItemData) => {
        setSelectedId(item?.id)
        onPressItem && onPressItem(item)
    }

    return (
        <FlatList
            testID={testID}
            contentContainerStyle={[styles.flatlist, containerStyle]}
            data={dataSet}
            keyExtractor={(item: any) => `${item?.id}`}
            horizontal={true}
            renderItem={null}
            CellRendererComponent={({ item, index }) => (
                <SegmentedCtrlItem
                    item={item}
                    index={index}
                    //@ts-ignore
                    size={getSize(size)}
                    textStyle={textStyle}
                    itemStyle={itemStyle}
                    itemSelectdBgColor={itemSelectdBgColor}
                    itemSelectdTxtColor={itemSelectdTxtColor}
                    onPress={() => handlePressItem(item)}
                    selectedId={selectedId}
                    testID={testID}
                    {...otherPressableProps}
                />
            )}
        />
    )
}

export default SegmentedControl
