import React from 'react'
import { Svg, Circle } from 'react-native-svg'
import { useTheme } from '../ThemeProvider/ThemeProvider'


export const RadioButtonUncheckedIcon = (props) => {
    const { theme } = useTheme()
    const color = theme?.colors?.border || '#808080' // Default to gray if undefined

    return (
        <Svg width="20" height="20" viewBox="0 0 20 20" fill="none" {...props}>
            <Circle
                cx="10"
                cy="10"
                r="9"
                stroke={color}
                strokeWidth="2"
                fill="none"
            />
        </Svg>
    )
}

