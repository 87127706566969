import { Dimensions, StyleSheet } from 'react-native'
import COLORS from '../../../utils/colors'
import { BuilderTheme } from '../../ThemeProvider/themes'

const createStyles = (theme?: BuilderTheme) => {
    return StyleSheet.create({
        subMenuContainer: {
            borderBottomRightRadius: theme?.spacing?.borderRadius || 30,
            backgroundColor: theme?.colors?.background || COLORS.COLOR_WHITE,
            width: 350,
            paddingVertical: theme?.spacing?.padding || 20,
            paddingHorizontal: theme?.spacing?.padding || 20,
        },
        sideMenuIcon: {
            width: 20,
            height: 20,
            marginRight: theme?.spacing?.margin || 10,
        },
        sideMenuTouchIcon: {
            color: theme?.colors?.primary || '#6200EA',
            width: 20,
            height: 20,
            marginRight: theme?.spacing?.margin || 10,
            tintColor: theme?.colors?.primary || '#6200EA',
        },
        sideMenuButtonStyle: {
            flexDirection: 'row',
            marginTop: theme?.spacing?.margin || 15,
            backgroundColor: theme?.colors?.background || COLORS.COLOR_WHITE,
            paddingVertical: theme?.spacing?.padding || 15,
            paddingHorizontal: theme?.spacing?.padding || 15,
        },
        sideMenuButtonTouchStyle: {
            flexDirection: 'row',
            marginTop: theme?.spacing?.margin || 15,
            backgroundColor: theme?.colors?.hover || '#F6F0FF',
            paddingVertical: theme?.spacing?.padding || 15,
            paddingHorizontal: theme?.spacing?.padding || 15,
        },
        sideMenuNameStyle: {
            fontSize: theme?.typography?.size?.lg?.fontSize || 18,
            color: theme?.colors?.text || COLORS.COLOR_GRAY,
        },
        sideMenuDescriptionStyle: {
            fontSize: theme?.typography?.size?.xs?.fontSize || 12,
            marginTop: theme?.spacing?.margin || 10,
            color: theme?.colors?.text || COLORS.COLOR_GRAY,
        },
        sideMenuDescriptionTouchStyle: {
            fontSize: theme?.typography?.size?.xs?.fontSize || 12,
            marginTop: theme?.spacing?.margin || 10,
            color: theme?.colors?.primary || '#334155',
        },
        sideMenuTouchNameStyle: {
            fontSize: theme?.typography?.size?.lg?.fontSize || 18,
            color: theme?.colors?.primary || '#6200EA',
        },
        mainView: {
            backgroundColor: theme?.colors?.background || '#F1F5F9',
            height: "100%",
            flexDirection: 'row',
        },
        subMenuTitle: {
            color: theme?.colors?.text || '#64748B',
            fontSize: theme?.typography?.size?.xl?.fontSize || 20,
            fontWeight: theme?.typography?.fontWeight?.bold || 'bold',
        },
        subMenuTextContainer: {
            display: 'flex',
            width: "100%",
            flexShrink: 1
        },
        mainMenuContainer: {
            backgroundColor: theme?.colors?.background || COLORS.COLOR_WHITE,
            width: 100,
            borderRightWidth: theme?.spacing?.borderWidth || 1,
            borderRightColor: theme?.colors?.border || COLORS.COLOR_LIGHTGRAY,
            flexDirection: 'column',
            justifyContent: 'space-between',
            paddingVertical: theme?.spacing?.padding || 20,
        },
        sidebuttoniconNameStyle: {
            paddingVertical: theme?.spacing?.padding || 15,
            width: '100%',
            alignItems: 'center',
        },
        sideTabIconStyle: {
            width: 16,
            height: 16,
            alignSelf: 'center',
        },
        sideTabIconPressStyle: {
            width: 16,
            height: 16,
            alignSelf: 'center',
            tintColor: theme?.colors?.hover || COLORS.COLOR_MEDIUMBLUE,
        },
        sideTabButtonTextStyle: {
            color: theme?.colors?.text || COLORS.COLOR_GRAY,
            fontSize: theme?.typography?.size?.['2xs']?.fontSize || 10,
            marginTop: theme?.spacing?.margin || 5,
            textAlign: 'center',
        },
        sideTabButtonTouchTextStyle: {
            color: theme?.colors?.primary || COLORS.COLOR_MEDIUMBLUE,
            fontSize: theme?.typography?.size?.['2xs']?.fontSize || 10,
            marginTop: theme?.spacing?.margin || 5,
            textAlign: 'center',
        },
        avtarStyle: {
            borderColor: theme?.colors?.hover || COLORS.COLOR_LIGHTPURPLE,
            width: 40,
            height: 40,
            borderRadius: 40 / 2,
        },
        imageStyle: {
            width: 30,
            height: 30,
            alignSelf: 'center',
        },
        avtarView: {
            alignItems: 'center',
        },
        backViewStyle: {
            backgroundColor: theme?.colors?.hover || COLORS.COLOR_MEDIUMBLUE,
            width: theme?.spacing?.borderWidth || 5,
        },
        tabView: {
            flexDirection: 'row',
            alignContent: 'center',
            width: '100%',
        },
    })
}

export default createStyles

