import React, { FC, ReactElement } from 'react'
import { Pressable, Text, Image, View, StyleSheet } from 'react-native'
import COLORS from '../../../src/utils/colors'
import { CardBaseProps, CardTitleProps } from '../Card/interface'
import Chips, { ChipsProps } from '../Chips'
import { useTheme } from '../ThemeProvider/ThemeProvider'
import { BuilderTheme } from '../ThemeProvider/themes'

export interface TaskCardProps extends CardBaseProps, CardTitleProps {
    detail?: React.ReactNode
    images?: (string | React.ReactNode)[]
    statusChipProps?: ChipsProps
    label?: string
    onPressText?: string
}

const TaskCardLoading: React.FC = () => {
    const { theme } = useTheme()
    const styles = createStyles(theme)

    return (
        <View style={styles.taskCardLoadingContainer}>
            <View style={styles.rowSpaceBetween}>
                <View style={styles.taskCardTitleLoading} />

                <View style={styles.taskCardImageLoading} />
            </View>

            <View style={styles.rowSpaceBetween}>
                <View style={styles.taskCardDetailLoading} />

                <View style={styles.taskCardOverdueLoading} />
            </View>
        </View>
    )
}

export const TaskCard: FC<TaskCardProps> = ({
    pressableProps,
    testID,
    containerStyle,
    title,
    detail,
    images,
    statusChipProps,
    loading,
}) => {
    const { theme } = useTheme()
    const styles = createStyles(theme)

    if (loading) {
        return <TaskCardLoading />
    }

    return (
        <Pressable {...pressableProps} style={[styles.taskCardContainer, containerStyle]}  testID={`${testID}-task-card`}>
            <View style={styles.spaceBetween}>
                <Text style={[styles.taskCardTitleStyle]}>{title}</Text>

                <View style={[styles.taskCardContentStyle]}>{detail}</View>
            </View>

            <View style={styles.spaceBetween}>
                <View testID={`${testID}-task-image`}>
                    {images &&
                        images.map((imageOrUrl, index) => {
                            const isUrl = typeof imageOrUrl === 'string'

                            if (isUrl) {
                                return (
                                    <Image
                                        key={imageOrUrl}
                                        style={[
                                            {
                                                ...styles.imageStyle,
                                                right: index * 16,
                                                zIndex:
                                                    images.length - index,
                                            },
                                        ]}
                                        source={{
                                            uri: imageOrUrl,
                                        }}
                                    />
                                )
                            } else if (React.isValidElement(imageOrUrl)) {
                                const node = imageOrUrl as ReactElement

                                return (
                                    <React.Fragment key={index}>
                                        {React.cloneElement(node, {
                                            style: [
                                                {
                                                    ...styles.imageStyle,
                                                    right: index * 16,
                                                    zIndex:
                                                        images.length -
                                                        index,
                                                },
                                                node.props.style,
                                            ],
                                        })}
                                    </React.Fragment>
                                )
                            }

                            return null
                        })}
                </View>

                {statusChipProps && <Chips {...statusChipProps} />}
            </View>
        </Pressable>
    )
}

const createStyles = (theme: BuilderTheme) => {
    return StyleSheet.create({
        taskCardContainer: {
            backgroundColor: theme?.colors?.background || 'white',
            borderRadius: theme?.spacing?.borderRadius || 6,
            borderLeftColor: COLORS.COLOR_PRIMARY_PURPLE,
            borderLeftWidth: theme?.spacing?.borderWidth || 8,
            paddingHorizontal: theme?.spacing?.padding || 20,
            paddingVertical: theme?.spacing?.padding || 16,
            flexDirection: 'row',
            justifyContent: 'space-between',
        },
        taskCardTitleStyle: {
            fontSize: theme?.typography?.size?.base?.fontSize || 16,
            fontWeight: theme?.typography?.fontWeight?.bold || 'bold',
            color: theme?.colors?.text || COLORS.COLOR_GRAY
        },
        taskCardContentStyle: {
            paddingTop: theme?.spacing?.padding || 5,
        },
        imageStyle: {
            height: theme?.icons?.size || 32,
            width: theme?.icons?.size || 32,
            resizeMode: 'cover',
            position: 'absolute',
            borderRadius: 100,
            borderWidth: theme?.spacing?.borderWidth || 1,
            borderColor: theme?.colors?.secondaryBackground || '#F0E5FF',
        },
        overdueContainerStyle: {
            marginTop: 14,
            backgroundColor: theme?.colors?.warning || '#FEF3C7',
            paddingVertical: theme?.spacing?.padding || 2,
            paddingHorizontal: theme?.spacing?.padding || 6,
            borderRadius: theme?.spacing?.borderRadius || 38,
        },
        overdueTextStyle: {
            fontSize: theme?.typography?.size?.xs?.fontSize || 12,
            color: theme?.colors?.warning || COLORS.COLOR_WARNING,
            fontWeight: theme?.typography?.fontWeight?.bold || 'bold',
        },
        taskCardLoadingContainer: {
            width: 327,
            height: 104,
            backgroundColor: theme?.colors?.background || 'white',
            borderRadius: theme?.spacing?.borderRadius || 6,
            borderLeftWidth: theme?.spacing?.borderWidth || 8,
            paddingHorizontal: theme?.spacing?.padding || 20,
            paddingVertical: theme?.spacing?.padding || 16,
            borderLeftColor: COLORS.COLOR_PRIMARY_PURPLE,
        },
        taskCardTitleLoading: {
            width: '30%',
            height: 20,
            backgroundColor: theme?.colors?.disabled || COLORS.COLOR_BTNGRAY,
            borderRadius: theme?.spacing?.borderRadius || 8,
            marginTop: theme?.spacing?.margin || 10,
        },
        taskCardImageLoading: {
            width: 20,
            height: 20,
            backgroundColor: theme?.colors?.disabled || COLORS.COLOR_BTNGRAY,
            borderRadius: theme?.spacing?.borderRadius || 8,
            marginTop: theme?.spacing?.margin || 10,
        },
        taskCardDetailLoading: {
            width: '50%',
            height: 20,
            backgroundColor: theme?.colors?.disabled || COLORS.COLOR_BTNGRAY,
            borderRadius: theme?.spacing?.borderRadius || 8,
            marginTop: theme?.spacing?.margin || 10,
        },
        taskCardOverdueLoading: {
            width: '20%',
            height: 20,
            backgroundColor: theme?.colors?.warning || COLORS.COLOR_WARNING,
            borderRadius: theme?.spacing?.borderRadius || 8,
            marginTop: theme?.spacing?.margin || 10,
        },
        spaceBetween: {
            justifyContent: 'space-between',
        },
        rowSpaceBetween: {
            flexDirection: 'row',
            justifyContent: 'space-between',
        },
    })
}


