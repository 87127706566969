import React, { useCallback, useState } from 'react';
import { View, TouchableOpacity } from 'react-native';
import { CalendarProvider, WeekCalendar } from 'react-native-calendars';
import moment from 'moment';
import createStyles from './styles';
import { WeeklyViewProps } from './interface';
import { useTheme } from '../../ThemeProvider/ThemeProvider';
import COLORS from '../../../utils/colors';

export const WeeklyView: React.FC<WeeklyViewProps> = ({
  renderArrow,
  inputValue,
  setInputValue,
  selectedDate,
  setSelectedDate,
  handleOnDayPress,
  markedDates,
  dateInput,
  theme: calendarTheme,
  ...props
}) => {
  const { theme } = useTheme();
  const styles = createStyles(theme);
  const handleNavigateWeek = useCallback(
    (direction) => {
      const newDate =
        direction === 'next'
          ? moment(selectedDate).add(1, 'weeks')
          : moment(selectedDate).subtract(1, 'weeks');
      setSelectedDate(newDate);
      setInputValue(newDate.format('MMM D, YYYY'));
    },
    [selectedDate, inputValue],
  );

  const [parentWidth, setParentWidth] = useState(0);

  const handleParentLayout = (event) => {
    const { width } = event.nativeEvent.layout;
    setParentWidth(width);
  };

  const calendarWidth = parentWidth; // 80% of parent width minus any padding/margin

  return (
    <View style={styles.container}>
      {dateInput}
      <View
        style={{
          alignItems: 'center',
          flexDirection: 'row',
          justifyContent: 'space-between',
        }}
      >
        <TouchableOpacity
          style={{
            width: '10%',
            alignItems: 'flex-start',
            justifyContent: 'center',
          }}
          onPress={() => handleNavigateWeek('prev')}
        >
          {renderArrow('left')}
        </TouchableOpacity>
        <View style={{ width: '80%' }} onLayout={handleParentLayout}>
          {parentWidth > 0 && (
            <CalendarProvider
              date={selectedDate.format('YYYY-MM-DD')}
            >
              <WeekCalendar
                calendarWidth={calendarWidth}
                firstDay={1}
                onDayPress={handleOnDayPress}
                markedDates={markedDates}
                theme={{
                  arrowStyle: { padding: 0 },
                  calendarBackground: theme?.colors?.background ?? 'transparent',
                  selectedDayBackgroundColor: theme?.colors?.primary ?? COLORS.COLOR_BLUE,
                  textDayFontWeight: theme?.typography?.fontWeight.normal ?? '400',
                  textDayFontSize: theme?.typography?.size?.sm?.fontSize ?? 14,
                  textSectionTitleColor: theme?.colors?.secondary ?? COLORS.COLOR_GRAY,
                  todayTextColor: theme?.colors?.text ?? COLORS.COLOR_BLACK,
                  dayTextColor: theme?.colors?.text ?? COLORS.COLOR_LIGHTBLACK,
                  textDisabledColor: theme?.colors?.disabled ?? COLORS.COLOR_GRAY_PLACEHOLDER,
                  monthTextColor: theme?.colors?.text ?? COLORS.COLOR_LIGHTBLACK,
                  textMonthFontWeight: theme?.typography?.fontWeight?.bold ?? '700',
                  textMonthFontSize: theme?.typography?.size?.sm?.fontSize ?? 14,
                  selectedDayTextColor: theme?.colors?.hover ?? COLORS.COLOR_HIGHLIGHTBLUE,
                  ...calendarTheme,
                }}
              />
            </CalendarProvider>
          )}
        </View>
        <TouchableOpacity
          style={{
            width: '10%',
            alignItems: 'flex-end',
            justifyContent: 'center',
          }}
          onPress={() => handleNavigateWeek('next')}
        >
          {renderArrow('right')}
        </TouchableOpacity>
      </View>
    </View>
  );
};
