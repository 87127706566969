import React, { useEffect, useRef } from 'react'
import { Animated, View } from 'react-native'
import { useTheme } from '../../ThemeProvider/ThemeProvider'
import {
    ProgressIndicatorBarProps,
    BaseProgressIndicatorProps,
} from '../interface'
import { createStyles } from '../style'

const ProgressIndicatorBar: React.FC<ProgressIndicatorBarProps & BaseProgressIndicatorProps> = (props) => {
    const { theme } = useTheme()
    const styles = createStyles(theme)
    const animatedWidth = useRef(new Animated.Value(0)).current

    const {
        indicatorToValue,
        indicatorDuration,
        headerIndicator,
        loaderContainer,
        loaderSmallContainer,
        loaderProgressStyle,
        testID,
    } = props

    useEffect(() => {
        Animated.timing(animatedWidth, {
            toValue: indicatorToValue,
            duration: indicatorDuration,
            useNativeDriver: false,
        }).start()
    }, [indicatorToValue, indicatorDuration])

    const interpolatedWidth = animatedWidth.interpolate({
        inputRange: [0, 100],
        outputRange: ['0%', '100%'],
        extrapolate: 'clamp',
    })

    const loaderContainerStyle = headerIndicator
        ? [styles.loaderContainer, loaderContainer]
        : [styles.loaderSmallContainer, loaderSmallContainer]

    return (
        <View style={loaderContainerStyle} testID={testID}>
            <Animated.View
                style={[
                    styles.loaderProgress,
                    loaderProgressStyle,
                    { width: interpolatedWidth },
                ]}
            />
        </View>
    )
}

export default ProgressIndicatorBar
