import React, { useState, useEffect, useCallback } from 'react';
import { View, Pressable, Text, StyleProp, TextStyle } from 'react-native';
import { useTheme } from '../ThemeProvider/ThemeProvider';
import { ICalendar } from './interface';
import createStyles from './styles';
import Calendar, { formatDate } from './Calendar';
import OutsideClickHandler from '../../molecules/OutsideClickHandler';
import COLORS from '../../utils/colors';

export type CalendarInputProps = ICalendar & {
    leftIcon?: React.ReactNode;
    placeHolder?: string;
    placeHolderStyle?: StyleProp<TextStyle>;
    popUpWidth?: number;
};

const CalendarInput: React.FC<CalendarInputProps> = ({
    currentDate,
    placeHolder,
    placeHolderStyle,
    leftIcon,
    popUpWidth,
    testID,
    markedDates = {},
    theme: propsTheme,
    ...rest
}) => {
    const { theme } = useTheme();
    const styles = createStyles(theme);

    const [isCalendarVisible, setIsCalendarVisible] = useState(false);
    const [selectedDateInput, setSelectedDateInput] = useState<string | undefined>(undefined);
    const [calendarMarkedDates, setCalendarMarkedDates] = useState(markedDates);

    const getSelectedDate = useCallback((markedDates: typeof calendarMarkedDates) => {
        const selectedDate = Object.keys(markedDates).find(
            (day) => markedDates[day].selected
        );
        return selectedDate ? formatDate(selectedDate) : undefined;
    }, []);

    const handleToggleCalendar = () => {
        setIsCalendarVisible(prevState => !prevState);
    };

    const handleOnDayPress = (day: any) => {
        const { dateString } = day;
        const updatedMarkedDates = { ...calendarMarkedDates };

        Object.values(updatedMarkedDates).forEach((val: any) => delete val.selected);
        updatedMarkedDates[dateString] = { ...updatedMarkedDates[dateString], selected: true };

        setCalendarMarkedDates(updatedMarkedDates);
    };

    useEffect(() => {
        setSelectedDateInput(getSelectedDate(calendarMarkedDates));
    }, [calendarMarkedDates, getSelectedDate]);

    return (
        <>
            <Pressable
                onPress={handleToggleCalendar}
                style={styles.container}
            >
                {leftIcon}
                <Text
                    style={[styles.textInputStyle, placeHolderStyle]}
                    testID={testID}
                >
                    {selectedDateInput || placeHolder || 'Select date'}
                </Text>
            </Pressable>
            <OutsideClickHandler
                onPress={() => setIsCalendarVisible(false)}
            >
                <View style={{ position: 'relative', zIndex: 1 }}>
                    <View
                        style={{
                            position: 'absolute',
                            width: popUpWidth || 300,
                        }}
                    >
                        {isCalendarVisible && (
                            <Calendar
                                currentDate={currentDate}
                                markedDates={calendarMarkedDates}
                                onDayPress={handleOnDayPress}
                                testID={testID + '-sub'}
                                theme={{
                                    calendarBackground: theme?.colors?.background ?? 'transparent',
                                    selectedDayBackgroundColor: theme?.colors?.primary ?? COLORS.COLOR_BLUE,
                                    selectedDayTextColor: theme?.colors?.secondary ?? COLORS.COLOR_HIGHLIGHTBLUE,
                                    textDayFontWeight: theme?.typography?.fontWeight?.normal ?? '400',
                                    textDayFontSize: theme?.typography?.size?.sm?.fontSize ?? 14,
                                    textSectionTitleColor: theme?.colors?.text ?? COLORS.COLOR_GRAY,
                                    todayTextColor: theme?.colors?.primary ?? COLORS.COLOR_BLACK,
                                    dayTextColor: theme?.colors?.text ?? COLORS.COLOR_LIGHTBLACK,
                                    textDisabledColor: theme?.colors?.disabled ?? COLORS.COLOR_GRAY_PLACEHOLDER,
                                    monthTextColor: theme?.colors?.text ?? COLORS.COLOR_LIGHTBLACK,
                                    textMonthFontWeight: theme?.typography?.fontWeight?.bold ?? '700',
                                    textMonthFontSize: theme?.typography?.size?.sm?.fontSize ?? 14,
                                    ...propsTheme,
                                }}
                                {...rest}
                            />
                        )}
                    </View>
                </View>
            </OutsideClickHandler>
        </>
    );
};

export default CalendarInput;
