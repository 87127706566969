import { StyleSheet } from 'react-native'
import { horizontalScale } from '../../../utils/screenRatio'
import { BuilderTheme } from '../../ThemeProvider/themes'

export default (theme: BuilderTheme) => StyleSheet.create({
    contanier: {
        width: '100%',
        height: 60,
        flexDirection: 'row',
        backgroundColor: theme?.colors?.background || 'white',
        borderBottomColor: theme?.colors?.border || '#E2E8F0',
        borderBottomWidth: theme?.spacing?.borderWidth || 1,
        alignItems: 'center',
        justifyContent: 'space-between',
    },
    headerLogoTextStyle: {
        color: theme?.colors?.text || '#334155',
        fontSize: theme?.typography?.size?.xl?.fontSize ?? 20,
        marginHorizontal: horizontalScale(10),
    },
    userSymbol: {
        color: theme?.colors?.text || 'black',
        width: 18,
        height: 18,
        fontWeight: theme?.typography?.fontWeight?.bold || 'bold',
    },
    symbol: {
        marginHorizontal: theme?.spacing?.margin ?? horizontalScale(4),
        color: theme?.colors?.text || 'black',
        width: 18,
        height: 18,
        fontWeight: theme?.typography?.fontWeight?.bold || 'bold',
    },
    loginText: {
        fontSize: theme?.typography?.size?.xs?.fontSize || 12,
        marginRight: theme?.spacing?.margin || horizontalScale(3),
    },
    cartStyle: {
        width: 15,
        height: 15,
        borderRadius: theme?.spacing?.borderRadius || 18 / 2,
        backgroundColor: theme?.colors?.primary || '#8833FF',
        alignItems: 'center',
        justifyContent: 'center',
        position: 'absolute',
        right: 10,
        bottom: 10,
    },
    flexStyle: {
        flexDirection: 'row',
        alignItems: 'center',
    },
    navButtonNameStyle: {
        color: theme?.colors?.text || '#334155',
        fontSize: theme?.typography?.size?.xs?.fontSize || 12,
        marginHorizontal: theme?.spacing?.margin || horizontalScale(6),
    },
    leftIconStyle: {
        color: theme?.colors?.secondary || '#94A3B8',
        marginLeft: theme?.spacing?.margin || 10,
    },
    avtarStyle: {
        borderColor: theme?.colors?.border || '#F0E5FF',
        width: 32,
        height: 32,
        borderRadius: 40 / 2,
        marginHorizontal: theme?.spacing?.margin ?? horizontalScale(6),
    },
    cartText: {
        color: theme?.colors?.secondary || 'white',
        fontSize: theme?.typography?.size?.['3xs']?.fontSize || 8,
    },
    inputStyle: {
        borderRadius: theme?.spacing?.borderRadius || 100,
        width: 360,
    },
    headerLogoStyle: {
        width: 149,
        height: 12,
        marginLeft: theme?.spacing?.margin ?? 40,
    },
})

